import React, { PureComponent } from "react";
import cn from "classnames";

import Button, { ButtonColor, ButtonType } from "src/components/Button";
import Paper from "src/components/Paper";
import Placeholder from "src/components/Placeholder";
import styles from "./InfoDialog.module.css";
import { ReactComponent as CloseIcon } from "src/assets/icons/ic-24-cross.svg";
import { ReactComponent as LittleYoda } from "src/assets/placeholders/little-yoda.svg";

interface Props {
  id: number;
  title: string;
  message?: string;
  messageAlign?: "left" | "center";
  buttons: Array<{
    title: string;
    type?: ButtonType;
    className?: string;
    onClick?: () => void;
  }>;
  image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

class InfoDialog extends PureComponent<Props> {
  public static defaultProps = {
    hasCloseButton: false,
    onClose: () => null
  };

  private renderButtons = () => {
    return this.props.buttons.map((button, index) => (
      <Button
        key={index}
        className={cn(styles.dialog__button, button.className)}
        buttonType={button.type || ButtonType.FILLED}
        onClick={button.onClick}
      >
        {button.title}
      </Button>
    ));
  };

  public render() {
    const {
      title,
      message,
      messageAlign,
      hasCloseButton,
      image = LittleYoda
    } = this.props;

    const messageClassName = cn(
      styles.placeholder__message,
      messageAlign === "left" && styles.placeholder_message_leftalign
    );

    return (
      <Paper>
        {hasCloseButton && (
          <div className={styles.dialog_header}>
            <Button
              className={styles.dialog__close_button}
              startIcon={CloseIcon}
              buttonColor={ButtonColor.SECONDARY}
              buttonType={ButtonType.FLAT}
              onClick={this.props.onClose}
            />
          </div>
        )}
        <div
          className={cn(
            styles.dialog_body,
            hasCloseButton && styles.dialog_body_with_close_button
          )}
        >
          <Placeholder
            title={title}
            message={message}
            messageClassName={messageClassName}
            ImageComponent={image}
            imageClassName={styles.placeholder__image}
            titleClassName={styles.placeholder__title}
          />
          {this.renderButtons()}
        </div>
      </Paper>
    );
  }
}

export default InfoDialog;
