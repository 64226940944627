import classNames from "classnames";
import Button, { ButtonType } from "src/components/Button";
import Typography, {
  TypographyColor,
  TypographyType,
} from "src/components/Typography";
import React from "react";
import styles from "./Placeholder.module.css";
import { Props } from "./Placeholder.type";

class Placeholder extends React.PureComponent<Props> {
  private renderImage = () => {
    const { renderImage, ImageComponent, imageClassName } = this.props;
    if (ImageComponent) {
      return (
        <div className={classNames(styles.placeholder__image, imageClassName)}>
          <ImageComponent />
        </div>
      );
    }
    if (renderImage) {
      return (
        <div className={classNames(styles.placeholder__image, imageClassName)}>
          {renderImage()}
        </div>
      );
    }
  };

  public render() {
    const {
      buttonTitle,
      message,
      onClickButton,
      title,
      className,
      titleClassName,
      messageClassName,
      buttonClassName,
    } = this.props;
    return (
      <div className={classNames(styles.placeholder, className)}>
        {this.renderImage()}
        {title && (
          <Typography
            className={classNames(styles.placeholder__title, titleClassName)}
            typographyType={TypographyType.H6}
            typographyColor={TypographyColor.PRIMARY}
          >
            {title}
          </Typography>
        )}
        {message && (
          <Typography
            className={classNames(
              styles.placeholder__message,
              messageClassName
            )}
            typographyType={TypographyType.SUBTITLE}
            typographyColor={TypographyColor.PRIMARY}
          >
            {message}
          </Typography>
        )}
        {buttonTitle && (
          <Button
            className={classNames(styles.placeholder__button, buttonClassName)}
            buttonType={ButtonType.FILLED}
            onClick={onClickButton}
          >
            {buttonTitle}
          </Button>
        )}
      </div>
    );
  }
}

export default Placeholder;
