import { AxiosInstance } from "axios";
import { blobToJson, isBlobResponse } from "./Backend.helper";
import {
  ErrorListeners,
  IBackendInterface
} from "./Backend.types";
import NetworkError from "./errors/NetworkError";
import OfflineError from "./errors/OfflineError";

export default class Backend implements IBackendInterface {
  public axios: AxiosInstance;
  private errorListeners: Map<ErrorListeners, Array<() => void>> = new Map();

  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
    this.createAxiosOfflineInterceptor(this.axios);
    this.createAxiosRefreshTokenInterceptor(this.axios);
  }

  private createAxiosOfflineInterceptor(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.request.use(response => {
      if (!navigator.onLine) {
        return Promise.reject(new OfflineError());
      }
      return response;
    });
  }

  private createAxiosRefreshTokenInterceptor(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.response.use(
      response => response,
      async error => {

        // Reject promise if usual error
        if (!error.response) {
          const isOffline =
            error.code === "ECONNABORTED" ||
            error.message === "Network Error" ||
            !navigator.onLine;

          if (isOffline) {
            return Promise.reject(new OfflineError());
          }

          return Promise.reject(new NetworkError(error.response));
        }

        if (error.response.status !== 401) {
          if (isBlobResponse(error.request, error.response)) {
            error.response.data = await blobToJson(error.response.data);
          }

          return Promise.reject(new NetworkError(error.response));
        }

        this.dispatchError(ErrorListeners.UnAuthorized);

        return Promise.reject(new NetworkError(error.response));
      }
    );
  }

  public addErrorListener(type: ErrorListeners, callback: () => void) {
    const listeners = this.errorListeners.get(type) || [];

    this.errorListeners.set(type, [...listeners, callback]);
  }

  public removeErrorListener(type: ErrorListeners, callback: () => void) {
    const listeners = this.errorListeners.get(type);

    if (!listeners) {
      return;
    }

    this.errorListeners.set(
      type,
      listeners.filter(listener => listener !== callback)
    );
  }

  private dispatchError(type: ErrorListeners) {
    const listeners = this.errorListeners.get(type) || [];

    listeners.forEach(listener => listener());
  }
}
