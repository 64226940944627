import { DEFAULT_MESSAGE } from "src/models/Error";

export default (self: any, path?: string) => (
  error: any,
  customMessage?: string
) => {
  console.error(customMessage || DEFAULT_MESSAGE, error);
  if (path) {
    Object.assign(self, {
      [path]: {
        customMessage,
        originalError: error,
      },
    });
  }
};

export * from "src/models/Error";
