import React, { Component } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import { ReactComponent as Mandalorian } from "src/assets/placeholders/mandalorian.svg";
import { ReactComponent as Spaceship } from "src/assets/placeholders/spaceship.svg";
import InfoDialog from "src/components/dialogs/InfoDialog";
import ModalDialog from "src/components/ModalDialog";
import { DialogData, DialogPayload, DialogType } from "src/types";
import styles from "./DialogsWrapper.module.css";

@observer
class DialogsWrapper extends Component {
  public static lastId = 0;

  @observable
  private dialogs: DialogData[] = [];

  private withAutoCloseWrapper = (id: number, handler?: () => void) => () => {
    if (handler) {
      handler();
    }
    const dialog = this.getDialogById(id);
    if (dialog) {
      if (!dialog.disableAutoClose) {
        this.closeDialog(id);
      }
    }
  };

  private getDialogById = (id: number) => {
    return this.dialogs.find(d => d.id === id);
  };

  private renderDialog = (dialog: DialogData) => {
    const {
      id,
      type,
      title,
      message,
      messageAlign,
      buttons,
      onClose,
      hasCloseButton
    } = dialog;

    switch (type) {
      case DialogType.COMMON:
        return (
          <InfoDialog
            title={title}
            message={message}
            messageAlign={messageAlign}
            buttons={buttons.map(btn => ({
              ...btn,
              onClick: this.withAutoCloseWrapper(id, btn.onClick)
            }))}
            image={Spaceship}
            hasCloseButton={hasCloseButton}
            onClose={this.withAutoCloseWrapper(id, onClose)}
            id={id}
          />
        );
      case DialogType.ERROR:
        return (
          <InfoDialog
            title={title}
            message={message}
            messageAlign={messageAlign}
            buttons={buttons.map(btn => ({
              ...btn,
              onClick: this.withAutoCloseWrapper(id, btn.onClick)
            }))}
            image={Mandalorian}
            hasCloseButton={hasCloseButton}
            onClose={this.withAutoCloseWrapper(id, onClose)}
            id={id}
          />
        );
      case DialogType.INFO:
      default:
        return (
          <InfoDialog
            title={title}
            message={message}
            messageAlign={messageAlign}
            buttons={buttons.map(btn => ({
              ...btn,
              onClick: this.withAutoCloseWrapper(id, btn.onClick)
            }))}
            hasCloseButton={hasCloseButton}
            onClose={this.withAutoCloseWrapper(id, onClose)}
            id={id}
          />
        );
    }
  };

  public closeAll = () => {
    this.dialogs = [];
  };

  public closeDialog = (dialogId: number) => {
    this.dialogs = this.dialogs.filter(dialog => dialog.id !== dialogId);
  };

  public addDialog = (payload: DialogPayload) => {
    DialogsWrapper.lastId = DialogsWrapper.lastId + 1;
    const id = DialogsWrapper.lastId;
    this.dialogs.push({
      ...payload,
      id
    });
    return id;
  };

  public render() {
    const dialogLength = this.dialogs.length;

    if (!dialogLength) {
      return null;
    }

    const dialog = this.dialogs[dialogLength - 1];
    const onEscPress = dialog?.closeOnEsc
      ? this.withAutoCloseWrapper(dialog.id, dialog.onClose)
      : undefined;

    return (
      <ModalDialog
        isOpen={true}
        className={styles.modal}
        onEscPress={onEscPress}
      >
        {this.renderDialog(dialog)}
      </ModalDialog>
    );
  }
}

export default DialogsWrapper;
