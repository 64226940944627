import { ReactComponent as CheckIcon } from "src/assets/icons/ic-24-check.svg";
import cn from "classnames";
import FlatList from "src/components/FlatList";
import ListItem from "src/components/ListItem";
import Paper from "src/components/Paper";
import React from "react";
import PerfectScrollbarContainer from "src/components/PerfectScrollbarContainer";
import { IFilterItemSnapshotIn } from "src/models/FilterItem";
import { capitalizeFirstLetter } from "src/utils/formatters";
import styles from "./DropDownMenu.module.css";
import PerfectScrollbar from "react-perfect-scrollbar";

interface DropDownMenuProps {
  items: IFilterItemSnapshotIn[];
  selected: IFilterItemSnapshotIn;
  onSelectItem(filter: IFilterItemSnapshotIn): void;
  className?: string;
}

export default class DropDownMenu extends React.Component<DropDownMenuProps> {
  private keyExtractor = (item: IFilterItemSnapshotIn) => item.id;

  private scrollBarRef = React.createRef<PerfectScrollbar>();

  public componentDidMount() {
    this.scrollBarRef.current?.updateScroll();
  }

  private renderItem = (item: IFilterItemSnapshotIn) => {
    const { selected, onSelectItem } = this.props;
    return (
      <ListItem
        itemId={item}
        onClick={onSelectItem}
        isSelected={item.id === selected.id}
        endIcon={CheckIcon}
      >
        {capitalizeFirstLetter(item.name)}
      </ListItem>
    );
  };

  public render() {
    const { items } = this.props;

    if (items.length > 16) {
      return (
        <Paper
          className={cn(
            styles.menu,
            this.props.className,
            styles.menuWithScroll
          )}
        >
          <PerfectScrollbarContainer
            className={styles.scrollBarContainer}
            options={{
              minScrollbarLength: 100,
            }}
            ref={this.scrollBarRef}
          >
            <FlatList
              data={items}
              renderItem={this.renderItem}
              keyExtractor={this.keyExtractor}
            />
          </PerfectScrollbarContainer>
        </Paper>
      );
    }

    return (
      <Paper className={cn(styles.menu, this.props.className)}>
        <FlatList
          data={items}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
        />
      </Paper>
    );
  }
}
