import { FormApi } from "final-form";
import { action, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import {
  Field,
  FieldRenderProps,
  Form,
  FormRenderProps
} from "react-final-form";

import { ReactComponent as HidePassword } from "src/assets/icons/ic-24-eye-closed.svg";
import { ReactComponent as ShowPassword } from "src/assets/icons/ic-24-eye-open.svg";
import { ReactComponent as DeathStar } from "src/assets/placeholders/death-star.svg";
import Button, { ButtonColor, ButtonSize, ButtonType } from "src/components/Button";
import Input from "src/components/Input";
import Typography, {
  TypographyColor,
  TypographyType
} from "src/components/Typography";
import styles from "./PasswordForm.module.css";

interface Props {
  onSubmit: (values: { password: string }) => void;
  form: FormApi<{ password: string }>;
}

@observer
export default class PasswordForm extends React.PureComponent<Props> {
  @observable public passwordIsVisible = false;

  @action
  private togglePasswordVisible = () => {
    this.passwordIsVisible = !this.passwordIsVisible;
  };

  public render() {
    return (
      <Form
        onSubmit={this.props.onSubmit}
        form={this.props.form}
        render={this.renderForm}
      />
    );
  }

  private renderPasswordIcon = observer(() => {
    if (this.passwordIsVisible) {
      return <ShowPassword onClick={this.togglePasswordVisible} />;
    }
    return <HidePassword onClick={this.togglePasswordVisible} />;
  });

  private renderPasswordField = (
    props: FieldRenderProps<string, HTMLInputElement>
  ) => {
    const error = props.meta.error || props.meta.submitError || "";

    return (
      <Observer>
        {() => (
          <Input
            {...props.input}
            type={this.passwordIsVisible ? "text" : "password"}
            endIcon={this.renderPasswordIcon}
            hasError={!!error}
            disabled={props.meta.submitting}
            data-testid="password"
            helperText={error}
          />
        )}
      </Observer>
    );
  };

  private validatePassword = (password: string): string | undefined => {
    if (!/\S/.test(password)) {
      return "Invalid password";
    }
  };

  private onButtonFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    event.target.blur();
  };

  private renderForm = ({
    dirty,
    submitting,
    handleSubmit
  }: FormRenderProps<{ password: string }>) => {
    return (
      <form id="form" className={styles.form} onSubmit={handleSubmit}>
        <Typography
          typographyType={TypographyType.H6}
          typographyColor={TypographyColor.PRIMARY}
          className={styles.form__title}
        >
          RESTRICTED AREA
        </Typography>

        <DeathStar className={styles.form__image} />

        <Typography
          typographyType={TypographyType.SUBTITLE}
          typographyColor={TypographyColor.SECONDARY}
          className={styles.form__label}
          component="label"
          htmlFor="password"
        >
          Password
        </Typography>
        <Field
          validate={this.validatePassword}
          id="password"
          name="password"
          render={this.renderPasswordField}
        />

        <Button
          type="submit"
          className={styles.form__button}
          disabled={!dirty || submitting}
          size={ButtonSize.LARGE}
          buttonType={ButtonType.FILLED}
          buttonColor={ButtonColor.PRIMARY}
          onFocus={this.onButtonFocus}
        >
          Get Access
        </Button>
      </form>
    );
  };
}
