import { types, flow, cast, Instance } from "mobx-state-tree";
import City, { ICitySnapshotIn } from "src/models/City";
import EmployeeType, { IEmployeeTypeSnapshotIn } from "src/models/EmployeeType";
import LoadingStore from "src/stores/base/LoadingStore";
import { loadCitiesFilters, loadEmployeeTypesFilters } from "src/api";

const FiltersStore = types
  .model({
    cities: types.array(City),
    employeeTypes: types.array(EmployeeType),
    fetchState: types.optional(LoadingStore, {}),
  })
  .actions((self) => ({
    _setCities(cities: ICitySnapshotIn[]) {
      self.cities = cast(cities);
    },
    _setEmployeeTypes(employeeTypes: IEmployeeTypeSnapshotIn[]) {
      self.employeeTypes = cast(employeeTypes);
    },
  }))
  .actions((self) => ({
    _loadCities: flow(function* () {
      const { data:cities } = yield loadCitiesFilters();
      self._setCities(cities);
    }),
    _loadEmployeeTypes: flow(function* () {
      const { data:employeeTypes } = yield loadEmployeeTypesFilters();
      self._setEmployeeTypes(employeeTypes);
    }),
  }))
  .actions((self) => ({
    loadFilters: flow(function* () {
      self.fetchState.setIsLoading(true);
      try {
        yield Promise.all([self._loadCities(), self._loadEmployeeTypes()]);
      } catch (e) {
      } finally {
        self.fetchState.setIsLoading(false);
      }
    }),
  }));

export default FiltersStore;
export interface IFiltersStore extends Instance<typeof FiltersStore> {}
