import React from "react";
import { ReactComponent as LittleYoda } from "src/assets/placeholders/little-yoda.svg";
import ModalDialog from "src/components/ModalDialog";
import Paper from "../Paper";
import Placeholder from "../Placeholder";
import Button, { ButtonColor, ButtonType } from "../Button";
import styles from "./FullScreenError.module.css";
import { ReactComponent as CloseIcon } from "src/assets/icons/ic-24-cross.svg";
import cn from "classnames";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { IErrorStore } from "src/stores/base/ErrorStore";
import { ProvisionMap } from "src/types";

interface FullScreenErrorProps {
  errorStore?: IErrorStore;
}

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  errorStore: rootStore.uiStore.errorStore,
});

@injectWithObserver(mapStateToProps)
class FullScreenError extends React.Component<FullScreenErrorProps> {
  render() {
    const { error, resetError, retry, needToRetry } = this.props.errorStore!;

    if (!error) return null;

    return (
      <ModalDialog
        isOpen={true}
        className={styles.modal}
        onEscPress={resetError}
      >
        <Paper className={styles.dialog__container}>
          <div className={cn(styles.dialog_body)}>
            <Button
              className={styles.dialog__close_button}
              startIcon={CloseIcon}
              buttonColor={ButtonColor.SECONDARY}
              buttonType={ButtonType.FLAT}
              onClick={resetError}
            />
            <Placeholder
              message={error.customMessage}
              ImageComponent={LittleYoda}
              imageClassName={styles.placeholder__image}
              titleClassName={styles.placeholder__title}
              messageClassName={styles.placeholder__message}
              buttonClassName={styles.placeholder__button}
              buttonTitle={needToRetry ? "Retry" : "OK"}
              onClickButton={needToRetry ? retry : resetError}
            />
          </div>
        </Paper>
      </ModalDialog>
    );
  }
}

export default FullScreenError;
