import CreateEditEmployeeStore from "../base/CreateEditEmployeeStore";
import {
  Instance,
  types,
  resolveIdentifier,
  getSnapshot,
  flow,
  getRoot,
} from "mobx-state-tree";
import { Employee as EmployeeBase, IEmployee } from "src/models/Employee";
import { updateEmployee, deleteEmployee } from "src/api";
import { IRootStore } from "src/stores/RootStore";

const Employee = EmployeeBase.named("Employee");

const EditEmployeeStore = CreateEditEmployeeStore.named("EditEmployeeStore")
  .props({
    employee: types.maybe(Employee),
  })
  .views((self) => ({
    get rootStore(): IRootStore {
      return getRoot<IRootStore>(self);
    },
  }))
  .actions((self) => ({
    _setEmployee(employee: IEmployee) {
      self.employee = employee;
    },
  }))
  .actions((self) => ({
    updateEmployee: flow(function* (formData: FormData, employeeId: string) {
      self.fetchState.setIsLoading(true);
      try {
        const { status } = yield updateEmployee(formData, employeeId);
        if (status >= 400) {
          throw new Error();
        }
        self.close();
        self.employeeStore.loadEmployees();
      } catch (e) {
        self.rootStore.uiStore.errorStore.onError(
          e,
          "Error occurred while updating employee",
          () => {
            (self as IEditEmployeeStore).updateEmployee(formData, employeeId);
          }
        );
      } finally {
        self.fetchState.setIsLoading(false);
      }
    }),
    deleteEmployee: flow(function* (employeeId: string) {
      self.fetchState.setIsLoading(true);

      try {
        const { status } = yield deleteEmployee(employeeId);
        if (status >= 400) {
          throw new Error();
        }
        self.close();
        self.employeeStore.loadEmployees();
      } catch (e) {
        self.rootStore.uiStore.errorStore.onError(
          e,
          "Error occurred while deleting employee",
          () => {
            (self as IEditEmployeeStore).deleteEmployee(employeeId);
          }
        );
      } finally {
        self.fetchState.setIsLoading(false);
      }
    }),
  }))
  .actions((self) => {
    const openBase = self.open;
    return {
      open(id: string) {
        const employee = resolveIdentifier(EmployeeBase, self, id);
        if (employee) {
          const snapshotFromEmployee = getSnapshot(employee);
          self._setEmployee(Employee.create({ ...snapshotFromEmployee }));
        }
        openBase();
      },
    };
  });

export default EditEmployeeStore;
export interface IEditEmployeeStore
  extends Instance<typeof EditEmployeeStore> {}
