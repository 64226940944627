import cn from "classnames";
import FadeAnimation, {
  FADE_DELAY,
  FADE_DURATION,
  FADE_TYPE,
} from "src/components/FadeAnimation";
import { MODAL_CONTAINER_ID } from "src/const/dom";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { DEFAULT_TIMEOUT, ESC } from "./Modal.const";
import styles from "./Modal.module.css";

export interface ModalProps {
  isOpen?: boolean;
  children?: React.ReactNode;
  timeout?: number;
  className?: string;
  onEscPress?: () => void;
  disableAutoFocus?: boolean;
}

const modalRoot = document.getElementById(MODAL_CONTAINER_ID)!;

const KEYDOWN_EVENT = "keydown";

class Modal extends PureComponent<ModalProps> {
  private containerRef = React.createRef<HTMLDivElement>();
  private el: HTMLDivElement;

  constructor(props: ModalProps) {
    super(props);
    this.el = document.createElement("div");
  }

  private onOpen() {
    if (!this.props.disableAutoFocus) {
      this.containerRef?.current?.focus();
    }
  }

  public componentDidMount() {
    window.addEventListener(KEYDOWN_EVENT, this.onKeyDown, false);
    modalRoot.appendChild(this.el);
    if (this.props.isOpen) {
      this.onOpen();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener(KEYDOWN_EVENT, this.onKeyDown, false);
    modalRoot.removeChild(this.el);
  }

  public componentDidUpdate(prevProps: ModalProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.onOpen();
      }
    }
  }

  private onKeyDown = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === ESC) {
      const { onEscPress } = this.props;
      if (onEscPress) {
        onEscPress();
      }
    }
  };

  public render() {
    const { isOpen, children, className, timeout } = this.props;

    return ReactDOM.createPortal(
      <FadeAnimation
        in={isOpen}
        durationIn={FADE_DURATION.DU_200}
        delayOut={FADE_DELAY.DL_200}
        animation={FADE_TYPE.OPACITY}
        enter={true}
        exit={true}
        unmountOnExit={true}
        timeout={timeout ?? DEFAULT_TIMEOUT}
      >
        <div
          ref={this.containerRef}
          tabIndex={0}
          className={cn(styles.modal, className)}
        >
          {children}
        </div>
      </FadeAnimation>,
      this.el
    );
  }
}

export default Modal;
