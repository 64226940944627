import React from "react";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { ProvisionMap } from "src/types";
import { IFiltersStore } from "src/stores/EmployeeStore/FiltersStore";
import { IEmployeeStore } from "src/stores/EmployeeStore";
import styles from "./Filters.module.css";
import SearchInput from "src/components/SearchInput";
import { observable, action } from "mobx";
import CitiesFilter from "./CitiesFilter";
import EmployeeTypesFilter from "./EmployeeTypesFilter";
import { ICitySnapshotIn } from "src/models/City";
import { IEmployeeTypeSnapshotIn } from "src/models/EmployeeType";

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  employeeStore: rootStore.employeeStore,
  filtersStore: rootStore.employeeStore.filtersStore,
});

interface FiltersProps {
  employeeStore?: IEmployeeStore;
  filtersStore?: IFiltersStore;
}

@injectWithObserver(mapStateToProps)
class Filters extends React.Component<FiltersProps> {
  @observable
  private timeout: null | NodeJS.Timeout = null;

  @action
  private setTimeout = (timeout: null | NodeJS.Timeout) => {
    this.timeout = timeout;
  };

  @observable
  private searchInputValue: string = "";

  @action
  private setSearchInputValue = (value: string) => {
    this.searchInputValue = value;
  };

  private delay = 500;

  private handleNameChange = (e: any) => {
    const name = e.target.value;
    this.setSearchInputValue(name);
    this.timeout && clearTimeout(this.timeout);
    const timeout = setTimeout(() => {
      this.props.employeeStore!.setName(name);
    }, this.delay);

    this.setTimeout(timeout);
  };

  private handleCityChange = (city: ICitySnapshotIn) => {
    this.props.employeeStore!.setCityId(city.id);
  };

  private handleEmployeeTypeChange = (
    employeeType: IEmployeeTypeSnapshotIn
  ) => {
    this.props.employeeStore!.setTypeId(employeeType.id);
  };

  public render() {
    const {
      fetchState: { isLoading: isEmployeeStoreLoading },
    } = this.props.employeeStore!;
    const {
      cities,
      employeeTypes,
      fetchState: { isLoading: isFiltersStoreLoading },
    } = this.props.filtersStore!;
    const disabled = isFiltersStoreLoading || isEmployeeStoreLoading;
    return (
      <div className={styles.filters}>
        <SearchInput
          inputContainerClassName={styles.filters__search_inputContainer}
          onChange={this.handleNameChange}
          hideHelperText={true}
          value={this.searchInputValue}
        />
        <div className={styles.filters__buttons}>
          <EmployeeTypesFilter
            employeeTypes={employeeTypes}
            handleEmployeeTypeChange={this.handleEmployeeTypeChange}
            disabled={disabled}
          />
          <CitiesFilter
            cities={cities}
            handleCityChange={this.handleCityChange}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

export default Filters;
