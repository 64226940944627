import React from "react";
import Filters from "./Filters";
import Button from "src/components/Button";
import styles from "./Header.module.css";
import { ReactComponent as Logo } from "src/assets/logo.svg";
import { ReactComponent as PlusIcon } from "src/assets/icons/ic-24-plus.svg";
import { ProvisionMap } from "src/types";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { ICreateEmployeeStore } from "src/stores/EmployeeStore/CreateEmployeeStore";
import ViewSwitcher from "./ViewSwitcher";
import { IUIStore } from "src/stores/UIStore";
import { IAuthStore } from "src/stores/AuthStore/AuthStore";
import { isEditMode } from "src/utils/permissions";
import {IRootStore} from 'src/stores/RootStore'

interface HeaderProps {
  rootStore?:IRootStore; 
  createEmployeeStore?: ICreateEmployeeStore;
  uiStore?: IUIStore;
  authStore?:IAuthStore
}

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  rootStore,
  createEmployeeStore: rootStore.employeeStore.createEmployeeStore,
  uiStore: rootStore.uiStore,
  authStore: rootStore.authStore
});
@injectWithObserver(mapStateToProps)
class Header extends React.Component<HeaderProps> {

  onClickLogut = async (event:React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    await this.props.rootStore?.logout();
  }

  public render() {
    const { open } = this.props.createEmployeeStore!;
    const { tableViewType, setTableViewType } = this.props.uiStore!;
    return (
      <div className={styles.header__container}>
        <header className={styles.header}>
          <div className={styles.header__left_block}>
            <div className={styles.header__logo}>
              <Logo />
            </div>
            <div className={styles.header__title}>
              <a href={window.location.href}>PHOTOS</a>
            </div>
            <Filters />
            <div>
              {isEditMode() && (
                <Button
                  onClick={open}
                  className={styles.header__addButton}
                  startIcon={PlusIcon}
                >
                  New
                </Button>
              )}
            </div>
            <ViewSwitcher
              selectedViewType={tableViewType}
              switchView={setTableViewType}
              className={isEditMode() ? styles.header__viewSwitcher : undefined}
            />
          </div>
          {(this.props.authStore?.currentUser && (
              <div
                onClick={this.onClickLogut}
                className={styles.header__logout}
              >
                <span className={styles.header__name}>
                  {this.props.authStore.name || "Logout"}
                </span>
                <span
                  className={styles["header__logout-text"]}
                  data-text="Logout"
                >
                  Logout
                </span>
              </div>
            )) ||
              null}
        </header>
      </div>
    );
  }
}

export default Header;
