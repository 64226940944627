import React from "react";
import { Router } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import {browserHistory, createStore} from './App.utils'
import { computed, observable } from "mobx";
import { observer, Provider } from "mobx-react";
import backend from "./services/Backend";
import Employees from "./views/Employees";
import SignIn from "./views/Signin";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ErrorListeners } from "./services/Backend/Backend.types";
import DialogController from "./services/DialogController/DialogController";
import DialogsWrapper  from "./components/DialogsWrapper";

const store = createStore();

@observer
class App extends React.Component {
  @observable private initialized = false;

  private dialogController = new DialogController();

  @computed
  get editMode(): boolean {
    return new URLSearchParams(browserHistory.location.search).has("edit")
  }

  @computed
  get isLogged() {
    return store!.authStore.isAuthorized;
  }

  public async componentDidMount() {
    backend.addErrorListener(ErrorListeners.UnAuthorized, this.onLogout);

    try {
      await store.authStore.getCurrentUser();
     
      if (this.isLogged && !this.editMode) {
        browserHistory.push("/");
      }
    }
    catch(_) {
    }
    finally {
      this.initialized = true;
    }
  }

  public componentWillUnmount() {
    backend.removeErrorListener(ErrorListeners.UnAuthorized, this.onLogout);
  }

  private onLogout = async () => {
    await store.logout();
    browserHistory.push("/signin");
  };

  private authorizedRoutes = () => {
    return (
      <Switch>
        <Route path="/">
          <Employees edit={this.editMode} />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  };

  private guestRoutes = () => {
    return (
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Redirect to="/signin" />
      </Switch>
    );
  };

  public render() {
    if (!this.initialized) {
      return null;
    }

    return (
      <Router history={browserHistory}>
        <Provider rootStore={store} dialogController={this.dialogController}>
          <ErrorBoundary>
            {this.isLogged ? this.authorizedRoutes() : this.guestRoutes()}
            <DialogsWrapper ref={this.dialogController.dialogsWrapperRef} />
          </ErrorBoundary>
        </Provider>
      </Router>
    )
  }
}

export default App;