export interface ErrorResponse {
  data?: { code?: string | number; message?: string };
  status: number;
}

export default class NetworkError extends Error {
  public code: number | undefined;
  public status: number | undefined;
  public data: any | undefined;

  constructor(error: ErrorResponse) {
    super();

    this.data = error.data;
    if (error.data?.code) {
      this.code = parseInt(String(error.data.code), 10);
    }

    const statusCode = error.status || error.data?.code;

    if (statusCode) {
      this.status = parseInt(String(statusCode), 10);
    }

    if (error.data?.message) {
      this.message = error.data.message;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError);
    }
  }
}
