import { inject, observer } from "mobx-react";
import { IStoresToProps } from "mobx-react/dist/types/IStoresToProps";
import { IReactComponent } from "mobx-react/dist/types/IReactComponent";

export default <S, P, I, C>(
  ...args: string[] | [IStoresToProps<S, P, I, C>]
) => <T extends IReactComponent<any>>(Component: T) => {
  if (typeof args[0] === "function") {
    return inject<S, P, I, C>(args[0] as IStoresToProps<S, P, I, C>)(
      observer(Component)
    );
  }
  return inject(...(args as string[]))(observer(Component));
};
