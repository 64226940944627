import React from "react";
import DropDownFilter from "../base/DropDownFilter";
import styles from "./CitiesFilter.module.css";
import { observer } from "mobx-react";
import { ICitySnapshotIn } from "src/models/City";

interface CitiesFilterProps {
  disabled?: boolean;
  cities: ICitySnapshotIn[];
  handleCityChange: (city: ICitySnapshotIn) => void;
}

const DefaultCityFilterValue = {
  id: "",
  name: "Все офисы",
};
@observer
class CitiesFilter extends React.Component<CitiesFilterProps> {
  public render() {
    const { cities, handleCityChange, disabled } = this.props;
    return (
      <DropDownFilter
        items={[DefaultCityFilterValue, ...cities]}
        onChange={handleCityChange}
        defaultItem={DefaultCityFilterValue}
        buttonClassName={styles.citiesFilter__button}
        menuClassName={styles.citiesFilter__menu}
        disabled={disabled}
      />
    );
  }
}

export default CitiesFilter;
