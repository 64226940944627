import React from "react";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { History } from "history";
import Header from "./components/Header";
import Placeholder from "src/components/Placeholder";
import { ReactComponent as PlaceholderIcon } from "src/assets/placeholders/at-m-aga-pic-empty.svg";
import Button, { ButtonColor, ButtonSize, ButtonType } from "src/components/Button";
import styles from "./Signin.module.css";
import SigninStore from './Signin.store'
import DialogController from "src/services/DialogController/DialogController";
import {IRootStore} from "src/stores/RootStore";

interface Props {
  rootStore: IRootStore;
  dialogController: DialogController;
  history: History
};

@injectWithObserver("rootStore", "dialogController")
export default class SignIn extends React.Component<Props> {
  private store:SigninStore;

  constructor(props:Props) {
    super(props);
    this.store = new SigninStore(props.rootStore.authStore, props.dialogController, props.history)
  }

  public componentDidMount(): void {
    this.store!.init();
  }

  onClickSignin = (event:React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.store.startGoogleSignin();
  }

  render() {
    return (
      <div className={styles.root}>
        <Header />
        <div className={styles.contentContainer}>
          <div className={styles.placeholder_container}>
            <Placeholder
              title={"Welcome to Photos. Please log in with your\n@mercurydevelopment Google account"}
              renderImage={() => <PlaceholderIcon />}
            />
            <Button
              buttonType={ButtonType.FILLED}
              buttonColor={ButtonColor.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={this.onClickSignin}
              disabled={this.store.googleSigninInProcess}
            >
            Log in
            </Button>
          </div>
        </div>
      </div>
    )
  }
}