import React from "react";
import styles from "./Loader.module.css";
import { ReactComponent as LoaderSvg } from "src/assets/placeholders/pic-loader-lightsaber.svg";

const Loader = () => {
  return (
    <div className={styles.loader_container}>
      <LoaderSvg />
    </div>
  );
};

export default Loader;
