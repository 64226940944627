export enum ButtonSize {
  SMALL,
  SMALL_WIDE,
  DEFAULT,
  LARGE
}

export enum ButtonType {
  FILLED,
  OUTLINED,
  INVERT,
  FLAT,
  TEXT,
  HALF_COLORED,
  GRAYED
}

export enum ButtonColor {
  NONE,
  PRIMARY,
  SECONDARY
}
