import cn from "classnames";
import styles from "src/components/FadeAnimation/FadeAnimation.module.css";
import {
  FADE_DELAY,
  FADE_DURATION,
} from "src/components/FadeAnimation/FadeAnimation.types";
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition";

function withDelay(className?: string, delay?: FADE_DELAY) {
  if (!className) {
    return className;
  }
  switch (delay) {
    case FADE_DELAY.DL_100:
      return cn(className, styles.fade_delay_100);
    case FADE_DELAY.DL_200:
      return cn(className, styles.fade_delay_200);
    case FADE_DELAY.DL_300:
      return cn(className, styles.fade_delay_300);
    case FADE_DELAY.DL_400:
      return cn(className, styles.fade_delay_400);
    case FADE_DELAY.DL_500:
      return cn(className, styles.fade_delay_500);
    case FADE_DELAY.DL_600:
      return cn(className, styles.fade_delay_600);
  }
  return className;
}

function withDuration(className?: string, duration?: FADE_DURATION) {
  if (!className) {
    return className;
  }
  switch (duration) {
    case FADE_DURATION.DU_100:
      return cn(className, styles.fade_duration_100);
    case FADE_DURATION.DU_200:
      return cn(className, styles.fade_duration_200);
    case FADE_DURATION.DU_400:
      return cn(className, styles.fade_duration_400);
    default:
    case FADE_DURATION.DU_300:
      return cn(className, styles.fade_duration_300);
  }
}

export function applyDuration(
  classNames: CSSTransitionClassNames,
  duration: {
    in?: FADE_DURATION;
    out?: FADE_DURATION;
  }
): CSSTransitionClassNames {
  return {
    ...classNames,
    enterActive: withDuration(classNames.enterActive, duration.in),
    appearActive: withDuration(classNames.appearActive, duration.in),
    exitActive: withDuration(classNames.exitActive, duration.out),
  };
}

export function applyDelay(
  classNames: CSSTransitionClassNames,
  delay: { in?: FADE_DELAY; out?: FADE_DELAY }
): CSSTransitionClassNames {
  return {
    ...classNames,
    enterActive: withDelay(classNames.enterActive, delay.in),
    appearActive: withDelay(classNames.appearActive, delay.in),
    exitActive: withDelay(classNames.exitActive, delay.out),
  };
}
