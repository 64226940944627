import React from "react";
import styles from "./ViewSwitcher.module.css";
import { ReactComponent as ListView } from "src/assets/icons/ic-24-view-title.svg";
import { ReactComponent as GridView } from "src/assets/icons/ic-24-view-grid.svg";
import { TableViewTypes } from "src/stores/UIStore";
import cn from "classnames";

interface ViewSwitcherProps {
  selectedViewType: TableViewTypes;
  switchView: (viewType: TableViewTypes) => void;
  className?: string;
}

const ViewSwitcher = ({
  selectedViewType,
  switchView,
  className,
}: ViewSwitcherProps) => {
  return (
    <div className={cn(styles.viewSwitcher, className)}>
      <ListView
        className={
          selectedViewType === TableViewTypes.LIST
            ? styles.selectedView
            : undefined
        }
        onClick={() => switchView(TableViewTypes.LIST)}
      />
      <GridView
        className={
          selectedViewType === TableViewTypes.GRID
            ? styles.selectedView
            : undefined
        }
        onClick={() => switchView(TableViewTypes.GRID)}
      />
    </div>
  );
};

export default ViewSwitcher;
