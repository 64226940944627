import { applySnapshot, types, Instance } from "mobx-state-tree";
import EmployeeStore from "./EmployeeStore";
import UIStore from "./UIStore";
import AuthStore from "./AuthStore";

const RootStore = types
.model({
  employeeStore: types.optional(EmployeeStore, {}),
  uiStore: types.optional(UIStore, {}),
  authStore: types.optional(AuthStore, {})
})
.actions(self => ({
  logout: async () => {
    await self.authStore.logout();
    applySnapshot(self, {});
  }
}));

export default RootStore;
export interface IRootStore extends Instance<typeof RootStore> {}
