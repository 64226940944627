import React from "react";
import CreateEditEmployee from "../base/CreateEditEmployee";
import { ProvisionMap } from "src/types";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { IFiltersStore } from "src/stores/EmployeeStore/FiltersStore";
import { ICreateEmployeeStore } from "src/stores/EmployeeStore/CreateEmployeeStore";
import Loader from "src/components/Loader";

interface CreateEmployeeProps {
  filtersStore?: IFiltersStore;
  createEmployeeStore?: ICreateEmployeeStore;
}

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  filtersStore: rootStore.employeeStore.filtersStore,
  createEmployeeStore: rootStore.employeeStore.createEmployeeStore,
});
@injectWithObserver(mapStateToProps)
class CreateEmployee extends React.Component<CreateEmployeeProps> {
  public render() {
    const { cities, employeeTypes } = this.props.filtersStore!;
    const {
      close,
      addEmployee,
      fetchState: { isLoading },
    } = this.props.createEmployeeStore!;
    return (
      <>
        {isLoading && <Loader />}
        <CreateEditEmployee
          employeeTypes={employeeTypes}
          cities={cities}
          onClose={close}
          onSubmit={addEmployee}
        />
      </>
    );
  }
}

export default CreateEmployee;
