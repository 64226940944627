import { types, Instance } from "mobx-state-tree";
import ErrorStore from "src/stores/base/ErrorStore";
import StorageItem from "src/utils/storage-item";

export enum TableViewTypes {
  LIST = 1,
  GRID,
}

interface TableViewStorageItemData {
  tableViewType: TableViewTypes;
}

const tableViewStorageItem = new StorageItem<TableViewStorageItemData>(
  localStorage,
  "UIStore:tableViewType"
);

const UIStore = types
  .model("UIStore", {
    errorStore: types.optional(ErrorStore, {}),
    tableViewType: TableViewTypes.GRID,
  })
  .actions((self) => ({
    setTableViewType(tableViewType: TableViewTypes) {
      self.tableViewType = tableViewType;
      tableViewStorageItem.stringifyAndSet({ tableViewType });
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const tableViewStorageItemData = tableViewStorageItem.getParsed();
      if (tableViewStorageItemData && tableViewStorageItemData.tableViewType) {
        self.setTableViewType(tableViewStorageItemData.tableViewType);
      }
    },
  }));

export default UIStore;
export interface IUIStore extends Instance<typeof UIStore> {}
