export const BASE_URL = process.env.REACT_APP_API_HOST || "";
const API_URL = `${BASE_URL}/api/v1`

export const Constants = {
  Urls: {
    employeesUrl: `${API_URL}/employees`,
    citiesUrl: `${API_URL}/cities`,
    employeeTypesUrl: `${API_URL}/employeeTypes`,
    accountUrl:`${API_URL}/account`,
    restrictedAccessUrl: `${API_URL}/restricted/access`
  }
}

