import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

@observer
export class ErrorBoundary extends React.Component {
  @observable
  hasError: boolean = false;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {}

  render() {
    return this.hasError ? <h3>Something went wrong</h3> : this.props.children;
  }
}
