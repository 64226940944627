import React from "react";
import Button, { ButtonSize, ButtonType } from "src/components/Button";
import { ReactComponent as ArrowDownIcon } from "src/assets/icons/ic-24-chevron-bottom.svg";
import { ReactComponent as ArrowUpIcon } from "src/assets/icons/ic-24-chevron-top.svg";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import Popover from "src/components/Popover";
import DropDownFilterMenu from "src/components/DropDownMenu";
import styles from "./Select.module.css";
import cn from "classnames";

interface SelectItem {
  id: string;
  name: string;
}

interface SelectProps {
  items: SelectItem[];
  disabled?: boolean;
  buttonClassName?: string;
  menuClassName?: string;
  popoverSpacerClassName?: string;
  onChange: (value: SelectItem) => void;
  defaultItem: SelectItem;
  placeholder?: string;
}

@observer
class Select extends React.Component<SelectProps> {
  private filterRef = React.createRef<HTMLButtonElement>();

  @observable
  private selectedItem: SelectItem = this.props.defaultItem;

  @observable
  private popoverIsOpen: boolean = false;

  @action
  private openPopover = () => {
    this.popoverIsOpen = true;
  };

  @action
  private closePopover = () => {
    this.popoverIsOpen = false;
  };

  @action
  private onSelectItem = (selectedItem: SelectItem) => {
    this.selectedItem = selectedItem;
    this.closePopover();
    this.props.onChange(selectedItem);
  };

  private renderPopover = () => (
    <Popover
      open={this.popoverIsOpen}
      onClose={this.closePopover}
      anchor={this.filterRef}
      placement="bottom-start"
    >
      <div
        className={cn(
          styles.popover__spacer,
          this.props.popoverSpacerClassName
        )}
      />
      <DropDownFilterMenu
        items={this.props.items}
        selected={this.selectedItem}
        onSelectItem={this.onSelectItem}
        className={this.props.menuClassName}
      />
    </Popover>
  );

  public render() {
    const { disabled, buttonClassName, placeholder = "" } = this.props;
    return (
      <>
        <Button
          className={buttonClassName}
          size={ButtonSize.SMALL_WIDE}
          buttonType={ButtonType.OUTLINED}
          disabled={disabled}
          endIcon={this.popoverIsOpen ? ArrowUpIcon : ArrowDownIcon}
          ref={this.filterRef}
          onClick={this.popoverIsOpen ? this.closePopover : this.openPopover}
          type="button"
        >
          <div className={styles.button__title}>
            {this.selectedItem.name || (
              <span className={styles.placeholder}>{placeholder}</span>
            )}
          </div>
        </Button>
        {this.renderPopover()}
      </>
    );
  }
}

export default Select;
