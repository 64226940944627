import { IRootStore } from "../stores/RootStore";
import DialogController from "../services/DialogController/DialogController";
export interface ProvisionMap {
  rootStore: IRootStore;
  dialogController: DialogController;
}

export enum DialogType {
  INFO,
  ERROR,
  COMMON
}

export interface DialogButtonPayload {
  title: string;
  onClick?: () => void;
}

export interface DialogPayload {
  type?: DialogType;
  title: string;
  message: string;
  messageAlign?: "left" | "center";
  buttons: DialogButtonPayload[];
  hasCloseButton?: boolean;
  closeOnEsc?: boolean;
  onClose?: () => void;
  stackTrace?: string;
  disableAutoClose?: boolean;
}

export type DialogData = DialogPayload & {
  id: number;
};