import {
  applySnapshot,
  cast,
  flow,
  getSnapshot,
  SnapshotIn,
  Instance,
  types
} from "mobx-state-tree";
import * as API from "src/api"
import { CLIENT_ID } from "../../const/google";

const AUTH_STORAGE_CURRENT_USER = "currentUser";

const CurrentUser = types
  .model("User", {
    userName: types.string,
    restrictedAccessAllowed: types.boolean
  })
  .views(self => ({
    get name() {
      return self.userName;
    }
  }));

interface ICurrentUser extends Instance<typeof CurrentUser> {} 
export interface ICurrentUserSnapshotIn extends SnapshotIn<typeof CurrentUser> {}

const AuthStore = types
  .model("AuthStore", {
    currentUser: types.maybeNull(CurrentUser),
  })
  .views(self => ({
    get name() {
      return self.currentUser?.name;
    },
    get isAuthorized() {
      return !!self.currentUser;
    },
    get isAuthorizedEditor() {
      return self.currentUser?.restrictedAccessAllowed;
    },
  }))
  .actions(self => ({
    removeStorage: () => {
      localStorage.removeItem(AUTH_STORAGE_CURRENT_USER)
    }
  }))
  .actions(self => ({
    clearUserData: () => {
      self.currentUser = null;
      self.removeStorage()
    }
  }))
  .actions(self => ({
    loginWithGoogle: flow(function*(payload: { code: string }): any {
      yield API.signInWithGoogle({
        code:payload.code,
        clientId:CLIENT_ID
      })
    }),
    logout: flow(function*() {
      try {
        yield API.logout();
      }
      catch (e) {

      }
      finally {
        self.clearUserData();
      }
    }),
    getRestricteAccess: flow(function*(payload: {password: string}):any {
      yield API.getRestrictedAccess(payload);
    }),
    saveToStorage: () => {
      localStorage.setItem(AUTH_STORAGE_CURRENT_USER, JSON.stringify(getSnapshot(self))
      );
    },
    restoreFromStorage: () => {
      const snapshot = localStorage.getItem(AUTH_STORAGE_CURRENT_USER);
      if (snapshot) {
        applySnapshot(self, JSON.parse(snapshot));
      }
    }
  }))
  .actions(self => ({
    setCurrentUser: (user: ICurrentUserSnapshotIn) => {
      self.currentUser = cast(user);
      self.saveToStorage();
    }
  }))
  .actions(self => ({
    getCurrentUser: flow(function*(): any {
      const { data } = yield API.getCurrentUser();
      self.setCurrentUser(data);
    })
  }));

export interface IAuthStore extends Instance<typeof AuthStore> {}
export default AuthStore;