import { History } from "history";
import {observable, action} from "mobx"
import Firebase from '../../services/Firebase'
import {IAuthStore} from 'src/stores/AuthStore/AuthStore'
import DialogController from "src/services/DialogController";

export default class SiginStore {
  private readonly firebase: Firebase = new Firebase()

  @observable
  public googleSigninInProcess = false;

  constructor(private readonly authStore: IAuthStore, private readonly dialogController: DialogController, private readonly history:History) {
  }

  @action
  public startGoogleSignin = async () => {
    this.googleSigninInProcess = true;
    try {
      await this.firebase.init();
      const code = await this.firebase.signInWithGoogle();
      if (code) {
        await this.signinWithGoogle(code);
      }
    }
    catch(e) {
      this.dialogController.showErrorDialog(e);
    }
    finally {
      this.googleSigninInProcess = false;
    }
  }


  private async signinWithGoogle(code: string) {
    await this.authStore.loginWithGoogle({code})
    await this.authStore.getCurrentUser()
    this.history.push("/")
  }

  public init = () => {
    if (this.authStore.isAuthorized) {
      this.history.push("/")
    }
  }
} 