import {
  applyDelay,
  applyDuration,
} from "src/components/FadeAnimation/FadeAnimation.helper";
import React from "react";
import { CSSTransition } from "react-transition-group";
import {
  CSSTransitionClassNames,
  CSSTransitionProps,
} from "react-transition-group/CSSTransition";
import styles from "./FadeAnimation.module.css";
import { FADE_DELAY, FADE_DURATION, FADE_TYPE } from "./FadeAnimation.types";

type Props = CSSTransitionProps & {
  animation: FADE_TYPE;
  delayIn?: FADE_DELAY;
  delayOut?: FADE_DELAY;
  durationIn?: FADE_DURATION;
  durationOut?: FADE_DURATION;
};

const FADE_OPACITY_ANIMATION_CLASSES: CSSTransitionClassNames = {
  enter: styles.fade__opacity_enter,
  enterActive: styles.fade__opacity_enterActive,
  appear: styles.fade__opacity_enter,
  appearActive: styles.fade__opacity_enterActive,
  exit: styles.fade__opacity_exit,
  exitActive: styles.fade__opacity_enterActive,
};

const FADE_LEFT_ANIMATION_CLASSES: CSSTransitionClassNames = {
  enter: styles.fade__left_enter,
  enterActive: styles.fade__left_enterActive,
  appear: styles.fade__left_enter,
  appearActive: styles.fade__left_enterActive,
  exit: styles.fade__left_exit,
  exitActive: styles.fade__left_exitActive,
};

const FADE_BOTTOM_ANIMATION_CLASSES: CSSTransitionClassNames = {
  enter: styles.fade__bottom_enter,
  enterActive: styles.fade__bottom_enterActive,
  appear: styles.fade__bottom_enter,
  appearActive: styles.fade__bottom_enterActive,
  exit: styles.fade__bottom_exit,
  exitActive: styles.fade__bottom_exitActive,
};

const FADE_TOP_ANIMATION_CLASSES: CSSTransitionClassNames = {
  enter: styles.fade__top_enter,
  enterActive: styles.fade__top_enterActive,
  appear: styles.fade__top_enter,
  appearActive: styles.fade__top_enterActive,
  exit: styles.fade__top_exit,
  exitActive: styles.fade__top_exitActive,
};
export default class FadeAnimation extends React.PureComponent<Props> {
  private get animationBaseClasses(): CSSTransitionClassNames {
    const { animation } = this.props;
    switch (animation) {
      case FADE_TYPE.RIGHT_TO_LEFT:
        return FADE_LEFT_ANIMATION_CLASSES;
      case FADE_TYPE.BOTTOM_TO_TOP:
        return FADE_BOTTOM_ANIMATION_CLASSES;
      case FADE_TYPE.TOP_TO_BOTTOM:
        return FADE_TOP_ANIMATION_CLASSES;
      case FADE_TYPE.OPACITY:
      default:
        return FADE_OPACITY_ANIMATION_CLASSES;
    }
  }

  private get animationClasses(): CSSTransitionClassNames {
    const { delayIn, delayOut, durationIn, durationOut } = this.props;

    return applyDelay(
      applyDuration(this.animationBaseClasses, {
        in: durationIn,
        out: durationOut ?? durationIn,
      }),
      { in: delayIn, out: delayOut }
    );
  }

  public render() {
    const {
      animation,
      delayOut,
      delayIn,
      durationIn,
      durationOut,
      ...rest
    } = this.props;

    return (
      <CSSTransition
        classNames={this.animationClasses}
        component={null}
        {...rest}
      >
        {this.props.children}
      </CSSTransition>
    );
  }
}
