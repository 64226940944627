import { Constants } from "src/config/Constants";
import backend from "src/services/Backend";

interface UrlParams {
  cityId: string;
  name: string;
  typeId: string;
}

const options = {
  headers:{
    "Content-Type":"application/json"
  }
}

export const loadEmployees = async ({ cityId, name, typeId }: UrlParams) => {
  const url = `${Constants.Urls.employeesUrl}?cityId=${cityId}&name=${name}&typeid=${typeId}`;
  return backend.axios.get(url)
};

export const addEmployee = async (formData: FormData) => {
  return backend.axios.post(Constants.Urls.employeesUrl,formData);
};

export const updateEmployee = async (
  formData: FormData,
  employeeId: string
) => {
  return backend.axios.patch(`${Constants.Urls.employeesUrl}/${employeeId}`, formData);
};

export const deleteEmployee = async (employeeId: string) => {

  return backend.axios.delete(`${Constants.Urls.employeesUrl}/${employeeId}`)
};

export const loadCitiesFilters = async () => {
  return backend.axios.get(Constants.Urls.citiesUrl)
};

export const loadEmployeeTypesFilters = async () => {
  return backend.axios.get(Constants.Urls.employeeTypesUrl)
};

export const signInWithGoogle = async ({ code, clientId}:{code:string, clientId:string}) => {
  return backend.axios.post(`${Constants.Urls.accountUrl}/google/login`, JSON.stringify({code, clientId}), options)
}

export const logout = async () => {
  return backend.axios.post(`${Constants.Urls.accountUrl}/logout`)
}

export const getCurrentUser = async () => {
  return backend.axios.get(`${Constants.Urls.accountUrl}/current`, options)
};

export const getRestrictedAccess = async (payload:{password:string}) => {
  return backend.axios.post(`${Constants.Urls.restrictedAccessUrl}`, JSON.stringify(payload), options)
}