import { types, cast, Instance, getParent } from "mobx-state-tree";
import { Employee, IEmployeeSnapshotIn } from "src/models/Employee";
import { flow, reaction } from "mobx";
import FiltersStore from "./FiltersStore";
import EditEmployeeStore from "./EditEmployeeStore";
import CreateEmployeeStore from "./CreateEmployeeStore";
import { IRootStore } from "src/stores/RootStore";
import { loadEmployees, deleteEmployee } from "src/api";
import LoadingStore from "../base/LoadingStore";

const EmployeeStore = types
  .model({
    filtersStore: types.optional(FiltersStore, {}),
    editEmployeeStore: types.optional(EditEmployeeStore, {}),
    createEmployeeStore: types.optional(CreateEmployeeStore, {}),
    fetchState: types.optional(LoadingStore, {}),
    employees: types.array(Employee),
    cityId: "",
    name: "",
    typeId: "",
  })
  .views((self) => ({
    get rootStore(): IRootStore {
      return getParent(self);
    },
  }))

  .views((self) => ({
    get count() {
      return self.employees.length;
    },
  }))
  .actions((self) => ({
    setCityId(cityId: string) {
      self.cityId = cityId;
    },
    setName(name: string) {
      self.name = name;
    },
    setTypeId(typeId: string) {
      self.typeId = typeId;
    },
  }))
  .actions((self) => ({
    _updateEmployees(employees: IEmployeeSnapshotIn[]) {
      self.employees = cast(employees);
    },
  }))
  .actions((self) => ({
    loadEmployees: flow(function* () {
      self.fetchState.setIsLoading(true);

      try {
        const { cityId, name, typeId } = self;
        const { data } = yield loadEmployees({
          cityId,
          name,
          typeId,
        });
        self._updateEmployees(data);
      } catch (e) {
        self.rootStore.uiStore.errorStore.onError(
          e,
          "Error occurred while loading employees",
          (self as IEmployeeStore).loadEmployees
        );
      } finally {
        self.fetchState.setIsLoading(false);
      }
    }),
  }))
  .actions((self) => ({
    deleteEmployee: flow(function* (employeeId: string) {
      self.fetchState.setIsLoading(true);

      try {
        const { status } = yield deleteEmployee(employeeId);
        if (status >= 400) {
          throw new Error();
        }
        self.loadEmployees();
      } catch (e) {
        self.rootStore.uiStore.errorStore.onError(
          e,
          "Error occurred while deleting employee",
          (self as IEmployeeStore).loadEmployees
        );
      } finally {
        self.fetchState.setIsLoading(false);
      }
    }),
  }))
  .actions((self) => ({
    init: flow(function* () {
      yield Promise.all([
        self.loadEmployees(),
        self.filtersStore.loadFilters(),
      ]);
    }),
  }))
  .actions((self) => ({
    afterCreate() {
      reaction(
        () => {
          return {
            cityId: self.cityId,
            name: self.name,
            typeId: self.typeId,
          };
        },
        () => {
          self.loadEmployees();
        }
      );
    },
  }));

export default EmployeeStore;
export interface IEmployeeStore extends Instance<typeof EmployeeStore> {}
