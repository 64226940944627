import { AxiosRequestConfig, AxiosResponse } from "axios";

export function isBlobResponse(
  request: AxiosRequestConfig,
  response: AxiosResponse
) {
  return (
    request.responseType === "blob" &&
    response.data instanceof Blob &&
    response.data.type &&
    response.data.type.toLowerCase().indexOf("json") !== -1
  );
}

export function blobToJson(data: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = JSON.parse(reader.result as string);
      resolve(result);
    };

    reader.onerror = () => {
      reject();
    };

    reader.readAsText(data);
  });
}
