import { types, getParent } from "mobx-state-tree";
import { IEmployeeStore } from "src/stores/EmployeeStore";
import LoadingStore from "src/stores/base/LoadingStore";

const CreateEditEmployeeStore = types
  .model({
    isOpened: false,
    fetchState: types.optional(LoadingStore, {}),
  })
  .views((self) => ({
    get employeeStore(): IEmployeeStore {
      return getParent<IEmployeeStore>(self);
    },
  }))
  .actions((self) => ({
    open() {
      self.isOpened = true;
    },
    close() {
      self.isOpened = false;
    },
  }));

export default CreateEditEmployeeStore;
