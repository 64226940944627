import { types } from "mobx-state-tree";

const LoadingStore = types
  .model("LoadingStore", {
    isLoading: false,
  })
  .actions((self) => {
    return {
      setIsLoading(loading: boolean) {
        self.isLoading = loading;
      },
    };
  });

export default LoadingStore;
