import { ESC } from "src/components/Modal";
import { POPPER_CONTAINER_ID } from "src/const/dom";
import { Modifiers } from "popper.js";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Popper, PopperChildrenProps } from "react-popper";
import styles from "./Popover.module.css";
import { PopoverContentProps, Props } from "./Popover.types";

const POPPER_MODIFIERS: Modifiers = {
  preventOverflow: { boundariesElement: "viewport" },
};

const KEYDOWN_EVENT = "keydown";
const CLICK_EVENT = "click";

const UPDATE_POSITION_INTERVAL = 30;
const TIMEOUT_FOR_UPDATE_ANIMATION = 600;

const PopoverContent: React.FunctionComponent<PopoverContentProps> = (
  props
) => {
  useEffect(() => {
    // update position after animation
    const interval = setInterval(
      props.popper.scheduleUpdate,
      UPDATE_POSITION_INTERVAL
    );
    const timeout = setTimeout(
      () => clearInterval(interval),
      TIMEOUT_FOR_UPDATE_ANIMATION
    );
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [props.popper.scheduleUpdate]);

  useEffect(() => {
    window.addEventListener(KEYDOWN_EVENT, props.onEscKey, false);
    window.addEventListener(CLICK_EVENT, props.onClickOutside, true);
    return () => {
      window.removeEventListener(KEYDOWN_EVENT, props.onEscKey, false);
      window.removeEventListener(CLICK_EVENT, props.onClickOutside, true);
    };
  }, [props.onEscKey, props.onClickOutside]);
  return (
    <div
      ref={props.popper.ref}
      style={props.popper.style}
      className={styles.popover}
      data-placement={props.popper.placement}
    >
      {props.children}
    </div>
  );
};

class Popover extends React.PureComponent<Props> {
  private onEscKey = (evt: KeyboardEvent) => {
    if (evt.keyCode === ESC) {
      this.props.onClose();
    }
  };

  private onClickOutside = (evt: MouseEvent) => {
    const { anchor, ignoreClickClassname, onClose } = this.props;

    const popper = document.getElementById(POPPER_CONTAINER_ID);
    const isOutsideClick = !popper?.contains(evt.target as Element);

    if (isOutsideClick) {
      const needToIgnore =
        ignoreClickClassname &&
        (evt.target as Element).classList.contains(ignoreClickClassname);

      if (needToIgnore) {
        evt.stopPropagation();
        onClose();
      } else {
        if (!anchor.current?.contains(evt.target as Element)) {
          onClose();
        }
      }
    }
  };

  private renderContent = (popper: PopperChildrenProps) => {
    return (
      <PopoverContent
        popper={popper}
        onEscKey={this.onEscKey}
        onClickOutside={this.onClickOutside}
      >
        {this.props.children}
      </PopoverContent>
    );
  };

  public render() {
    const { anchor, open, placement } = this.props;
    if (anchor.current && open) {
      return ReactDOM.createPortal(
        <Popper
          placement={placement}
          referenceElement={anchor.current}
          positionFixed={true}
          modifiers={POPPER_MODIFIERS}
        >
          {this.renderContent}
        </Popper>,
        document.getElementById(POPPER_CONTAINER_ID)!
      );
    }
    return null;
  }
}

export default Popover;
