import React from "react";
import styles from "./Form.module.css";
import { Controller, FormContextValues } from "react-hook-form";

import { ICitySnapshotIn } from "src/models/City";
import { IEmployeeTypeSnapshotIn } from "src//models/EmployeeType";
import Typography, {
  TypographyType,
  TypographyColor,
} from "src/components/Typography";
import Input from "src/components/Input";
import { IEmployeeSnapshotIn } from "src/models/Employee";
import { IFilterItemSnapshotIn } from "src/models/FilterItem";
import Select from "./Select";
import { ReactComponent as EditIcon } from "src/assets/icons/ic-24-edit.svg";
import { ReactComponent as LoadIcon } from "src/assets/icons/ic-24-cloud.svg";
import PhotoPlaceholder from "src/assets/icons/pic-placeholder-attack.svg";
import { Line } from "rc-progress";
import cn from "classnames";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { ProvisionMap } from "src/types";
import { IErrorStore } from "src/stores/base/ErrorStore";
import { observable, action } from "mobx";

export enum EmployeeFormFields {
  FIRST_NAME = "firstName",
  MIDDLE_NAME = "middleName",
  LAST_NAME = "lastName",
  CITY = "city",
  EMPLOYEE_TYPE = "employeeType",
  PHOTO = "photo",
}

export type EmployeeFormData = {
  [EmployeeFormFields.FIRST_NAME]: string;
  [EmployeeFormFields.MIDDLE_NAME]: string;
  [EmployeeFormFields.LAST_NAME]: string;
  [EmployeeFormFields.CITY]: ICitySnapshotIn;
  [EmployeeFormFields.EMPLOYEE_TYPE]: IEmployeeTypeSnapshotIn;
  [EmployeeFormFields.PHOTO]: FileList | null;
};

interface FormProps {
  form: FormContextValues<EmployeeFormData>;
  employee?: IEmployeeSnapshotIn;
  cities: ICitySnapshotIn[];
  getDefaultCityFieldValue: () => IFilterItemSnapshotIn;
  employeeTypes: IEmployeeTypeSnapshotIn[];
  getDefaultEmployeeTypeFieldValue: () => IFilterItemSnapshotIn;
  errorStore?: IErrorStore;
}

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  errorStore: rootStore.uiStore.errorStore,
});

@injectWithObserver(mapStateToProps)
class Form extends React.Component<FormProps> {
  @observable
  private photoPreview: string | null =
    (this.props.employee && this.props.employee.photoUrl) || null;

  @action
  private setPhotoPreview = (photoPreview: string | null) => {
    this.photoPreview = photoPreview;
  };

  @observable
  private isPhotoLoading: boolean = false;

  @action
  private setPhotoIsLoading = (isPhotoLoading: boolean) => {
    this.isPhotoLoading = isPhotoLoading;
  };

  @observable
  private progress: number = 0;

  @action
  private setProgress = (progress: number) => {
    this.progress = progress;
  };

  private onCityFieldChange = (city: ICitySnapshotIn) => {
    this.props.form.setValue("city", city);
  };

  private onEmployeeTypeFieldChange = (
    employeeType: IEmployeeTypeSnapshotIn
  ) => {
    this.props.form.setValue("employeeType", employeeType);
  };

  private checkImageSizeAcceptability = (img: HTMLImageElement) => {
    return img.width === img.height && img.width >= 450 && img.height >= 450;
  };

  private startLoadingProgress = () => {
    this.setProgress(0);
    return setInterval(() => {
      if (this.progress < 80) {
        this.setProgress(this.progress + 20);
      }
    }, 100);
  };

  private updatePhotoPreview = (reader: FileReader) => {
    const img = document.createElement("img");

    img.addEventListener("load", () => {
      if (this.checkImageSizeAcceptability(img)) {
        this.setPhotoPreview(reader.result as string);
      } else {
        this.props.form.setValue(EmployeeFormFields.PHOTO, null);
        this.props.errorStore!.onError(
          null,
          "You can upload square images only. The preferred image size is 450x450 pixels or larger"
        );
      }
    });

    img.src = reader.result as string;
  };

  private loadPreview = (file?: File | null) => {
    if (!file) {
      this.setPhotoPreview(null);
      return;
    }

    this.setPhotoIsLoading(true);
    const interval = this.startLoadingProgress();

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        clearInterval(interval);
        this.setProgress(100);
        this.setPhotoIsLoading(false);

        this.updatePhotoPreview(reader);
      },
      false
    );

    reader.readAsDataURL(file);
  };

  private onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.form.getValues(EmployeeFormFields.PHOTO)) {
      this.setPhotoPreview(null);
    }
    let file: File | null = null;

    if (target && target.files && target.files.length) {
      const [firstFile] = target.files as any;

      file = (firstFile.type.match(/^image\//) && firstFile) || file;
    }

    if (file) {
      this.loadPreview(file);
    }
  };

  private renderPhotoLoad = () => {
    return (
      <label className={styles.form__loadPhoto}>
        {this.photoPreview ? (
          <div className={styles.form__loadPhoto__img_container}>
            <div className={styles.form__loadPhoto__img_button_container}>
              <EditIcon className={styles.form__loadPhoto__img_button} />
            </div>
            <div
              className={styles.form__loadPhoto_img}
              style={{
                backgroundImage: `url('${this.photoPreview || ""}'), url('${
                  (this.photoPreview && PhotoPlaceholder) || ""
                }')`,
              }}
            ></div>
          </div>
        ) : (
          <div className={styles.form__loadPhoto_placeholder}>
            {this.isPhotoLoading ? (
              <>
                <Line
                  className={styles.form__loadPhoto_placeholder_progressBar}
                  trailColor="#BCCFFB"
                  strokeColor="#5C8DF6"
                  percent={this.progress}
                />
                <p className={styles.form__loadPhoto_placeholder_progressCount}>
                  {this.progress}%
                </p>
              </>
            ) : (
              <>
                <LoadIcon className={styles.form__loadPhoto_placeholder_icon} />
                <p className={styles.form__loadPhoto_placeholder_title}>
                  Choose Photo*
                </p>
              </>
            )}
            <p className={styles.form__loadPhoto_placeholder_size}>
              Size 450x450
            </p>
          </div>
        )}

        <input
          className={styles.form__loadPhoto_control}
          ref={this.props.form.register({
            validate: (value) =>
              !!(
                (value && value[0]) ||
                (this.props.employee && this.props.employee.photoUrl)
              ),
          })}
          name={EmployeeFormFields.PHOTO}
          type="file"
          accept="image/*"
          capture
          onChange={this.onChange}
        />
      </label>
    );
  };

  renderFieldLabel = (label: string, className?: string) => {
    return (
      <Typography
        className={className}
        typographyType={TypographyType.SUBTITLE}
        typographyColor={TypographyColor.SECONDARY}
      >
        {label}
      </Typography>
    );
  };

  public render() {
    const {
      form,
      employeeTypes,
      cities,
      getDefaultEmployeeTypeFieldValue,
      getDefaultCityFieldValue,
    } = this.props;
    return (
      <form className={styles.form__container}>
        {this.renderPhotoLoad()}
        <div className={styles.form__fields}>
          <div className={styles.form__field}>
            <label className={styles.form__field_label}>
              {this.renderFieldLabel("First Name*")}
            </label>
            <Input
              inputContainerClassName={cn(
                styles.form__field_inputContainer,
                form.errors[EmployeeFormFields.FIRST_NAME] &&
                  styles.form__field_error
              )}
              inputClassName={styles.form__field_input}
              inputRef={form.register({
                maxLength: 255,
                validate: (value) => !!value.trim(),
              })}
              name={EmployeeFormFields.FIRST_NAME}
              placeholder="Enter First Name"
              hideHelperText={true}
            />
          </div>
          <div className={styles.form__field}>
            <label className={styles.form__field_label}>
              {this.renderFieldLabel("Middle Name")}
            </label>
            <Input
              inputContainerClassName={styles.form__field_inputContainer}
              inputClassName={styles.form__field_input}
              inputRef={form.register}
              name={EmployeeFormFields.MIDDLE_NAME}
              placeholder="Enter Middle Name"
              hideHelperText={true}
            />
          </div>
          <div className={styles.form__field}>
            <label className={styles.form__field_label}>
              {this.renderFieldLabel("Last Name*")}
            </label>
            <Input
              inputContainerClassName={cn(
                styles.form__field_inputContainer,
                form.errors[EmployeeFormFields.LAST_NAME] &&
                  styles.form__field_error
              )}
              inputClassName={styles.form__field_input}
              inputRef={form.register({
                maxLength: 255,
                validate: (value) => !!value.trim(),
              })}
              name={EmployeeFormFields.LAST_NAME}
              placeholder="Enter Last Name"
              hideHelperText={true}
            />
          </div>
          <div className={styles.form__field}>
            <label className={styles.form__field_label}>
              {this.renderFieldLabel("Type*")}
            </label>
            <Controller
              as={
                <Select
                  items={employeeTypes}
                  onChange={this.onEmployeeTypeFieldChange}
                  defaultItem={getDefaultEmployeeTypeFieldValue()}
                  buttonClassName={cn(
                    styles.form__field_dropDownButton,
                    form.errors[EmployeeFormFields.EMPLOYEE_TYPE] &&
                      styles.form__field_error
                  )}
                  menuClassName={styles.form__field_dropDownMenu}
                  popoverSpacerClassName={
                    styles.form__field_dropDownPopoverSpacer
                  }
                  placeholder="Choose Type"
                />
              }
              name={EmployeeFormFields.EMPLOYEE_TYPE}
              control={form.control}
              rules={{ required: true }}
            />
          </div>
          <div className={styles.form__field}>
            <label className={styles.form__field_label}>
              {this.renderFieldLabel("City*")}
            </label>
            <Controller
              as={
                <Select
                  items={cities}
                  onChange={this.onCityFieldChange}
                  defaultItem={getDefaultCityFieldValue()}
                  buttonClassName={cn(
                    styles.form__field_dropDownButton,
                    form.errors[EmployeeFormFields.CITY] &&
                      styles.form__field_error
                  )}
                  menuClassName={styles.form__field_dropDownMenu}
                  popoverSpacerClassName={
                    styles.form__field_dropDownPopoverSpacer
                  }
                  placeholder="Choose City"
                />
              }
              name={EmployeeFormFields.CITY}
              control={form.control}
              rules={{ required: true }}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default Form;
