export enum FADE_TYPE {
  RIGHT_TO_LEFT,
  BOTTOM_TO_TOP,
  TOP_TO_BOTTOM,
  OPACITY
}

export enum FADE_DELAY {
  DL_100,
  DL_200,
  DL_300,
  DL_400,
  DL_500,
  DL_600
}

export enum FADE_DURATION {
  DU_100,
  DU_200,
  DU_300,
  DU_400
}
