import React from "react";
import { createForm, FormApi } from "final-form";
import {computed} from 'mobx';
import EmployeesTable from "./EmployeesTable";
import Header from "./Header";
import { ProvisionMap } from "src/types";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { IEmployeeStore } from "src/stores/EmployeeStore";
import EditEmployee from "./EmployeesTable/EditEmployee";
import CreateEmployee from "./EmployeesTable/CreateEmployee";
import { ICreateEmployeeStore } from "src/stores/EmployeeStore/CreateEmployeeStore";
import { IEditEmployeeStore } from "src/stores/EmployeeStore/EditEmployeeStore";
import styles from "./Employees.module.css";
import Loader from "src/components/Loader";
import Paper from "src/components/Paper"
import Placeholder from "src/components/Placeholder";
import { IUIStore } from "src/stores/UIStore";
import { ReactComponent as PlaceholderIcon } from "src/assets/placeholders/at-m-aga-pic-empty.svg";
import { IAuthStore } from "src/stores/AuthStore/AuthStore";
import DialogController from "src/services/DialogController/DialogController";
import PasswordForm from "./PasswordForm";
import FullScreenError from "src/components/FullScreenError";
import {IRootStore} from 'src/stores/RootStore'

// import {browserHistory} from 'src/App.utils'

interface EmployeesProps {
  edit:boolean;
  rootStore?:IRootStore;
  employeeStore?: IEmployeeStore;
  createEmployeeStore?: ICreateEmployeeStore;
  editEmployeeStore?: IEditEmployeeStore;
  uiStore?: IUIStore;
  authStore?: IAuthStore;
  dialogController?: DialogController;
}

const mapStateToProps = ({ rootStore, dialogController }: ProvisionMap) => ({
  rootStore,
  employeeStore: rootStore.employeeStore,
  createEmployeeStore: rootStore.employeeStore.createEmployeeStore,
  editEmployeeStore: rootStore.employeeStore.editEmployeeStore,
  uiStore: rootStore.uiStore,
  authStore: rootStore.authStore,
  dialogController: dialogController
});
@injectWithObserver(mapStateToProps)
class Employees extends React.Component<EmployeesProps> {

  private readonly form: FormApi<{ password: string }>;

  @computed
  get isAuthorizedEdior() {
    return this.props.authStore?.isAuthorizedEditor;
  }

  constructor(props:EmployeesProps) {
    super(props);
    this.form = createForm({ onSubmit: this.onSubmit });
  }

  public componentDidMount() {
    this.props.employeeStore?.init();
  }

  private renderContent = () => {
    const {
      count,
      fetchState: { isLoading },
    } = this.props.employeeStore!;

    if (isLoading) {
      return <Loader />;
    }
    return count > 0 ? (
      <EmployeesTable />
    ) : (
      <div className={styles.noData_placeholder_container}>
        <Placeholder
          title={"По вашему запросу ничего не найдено"}
          renderImage={() => <PlaceholderIcon />}
        />
      </div>
    );
  };

  private onSubmit = async (values: { password: string }) => {
    try {
      await this.props.authStore?.getRestricteAccess(values);
      await this.props.authStore?.getCurrentUser();
    }
    catch(e) {
      if (e.status ===  401) {
        return await this.props.rootStore?.logout();
      }

      this.props.dialogController?.showErrorDialog({
        name:'',
        title: "Access denied!",
        message: e.status === 403 ? ( e.message || "Invalid password.") : "Something went wrong, try again."
      })
    }
    finally {
      setTimeout(() => {
        this.form.reset();
      });
    }
  }

  public render() {
    if (this.props.edit) {
      if (this.isAuthorizedEdior) {
        const { isOpened: isEditEmployeeStoreOpened } = this.props.editEmployeeStore!;
        const { isOpened: isCreateEmployeeStoreOpened } = this.props.createEmployeeStore!;
        const { errorStore: { error }} = this.props.uiStore!;
        return (
          <>
            {error && <FullScreenError />}
            <div className={styles.root}>
              <Header />
              <div className={styles.contentContainer}>{this.renderContent()}</div>
              {isEditEmployeeStoreOpened && <EditEmployee />}
              {isCreateEmployeeStoreOpened && <CreateEmployee />}
            </div>
          </>
        )
      }
      
      return (
        <div className={styles.substrate}>
          <Paper className={styles.paper}>
            <PasswordForm
              onSubmit={this.onSubmit}
              form={this.form}
            />
          </Paper>
        </div>
      )
    }

    return (
      <>
        <div className={styles.root}>
          <Header />
          <div className={styles.contentContainer}>{this.renderContent()}</div>
        </div>
      </>
    );
  }
}

export default Employees;
