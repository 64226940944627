import React from "react";
import CreateEditEmployee from "../base/CreateEditEmployee";
import { ProvisionMap } from "src/types";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import { IFiltersStore } from "src/stores/EmployeeStore/FiltersStore";
import { IEditEmployeeStore } from "src/stores/EmployeeStore/EditEmployeeStore";
import { IEmployeeStore } from "src/stores/EmployeeStore";
import Loader from "src/components/Loader";
import { DELETE_CONFIRMATION_QUESTION } from "src/const/messages";

interface EditEmployeeProps {
  employeeStore?: IEmployeeStore;
  filtersStore?: IFiltersStore;
  editEmployeeStore?: IEditEmployeeStore;
}

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  employeeStore: rootStore.employeeStore,
  filtersStore: rootStore.employeeStore.filtersStore,
  editEmployeeStore: rootStore.employeeStore.editEmployeeStore,
});
@injectWithObserver(mapStateToProps)
class EditEmployee extends React.Component<EditEmployeeProps> {
  private onDeleteEmployee = (employeeId: string) => {
    const { deleteEmployee } = this.props.editEmployeeStore!;
    if (window.confirm(DELETE_CONFIRMATION_QUESTION)) {
      deleteEmployee(employeeId);
    }
  };

  private onSubmit = (formData: FormData) => {
    const { updateEmployee } = this.props.editEmployeeStore!;
    const { id } = this.props.editEmployeeStore!.employee!;
    updateEmployee(formData, id);
  };

  public render() {
    const { cities, employeeTypes } = this.props.filtersStore!;
    const {
      close,
      employee,
      fetchState: { isLoading },
    } = this.props.editEmployeeStore!;
    return (
      <>
        {isLoading && <Loader />}
        <CreateEditEmployee
          employee={employee}
          employeeTypes={employeeTypes}
          cities={cities}
          onClose={close}
          onSubmit={this.onSubmit}
          onDelete={this.onDeleteEmployee}
        />
      </>
    );
  }
}

export default EditEmployee;
