import { ReactComponent as IconSvgClear } from "src/assets/icons/ic-24-cross.svg";
import { ReactComponent as IconSvgSearch } from "src/assets/icons/ic-24-search.svg";
import Input from "src/components/Input";
import { InputProps } from "src/components/Input/Input.types";
import React, { PureComponent } from "react";

class SearchInput extends PureComponent<InputProps> {
  public inputRef = React.createRef<HTMLInputElement>();

  public onClear = () => {
    const { onChange } = this.props;
    if (onChange) {
      const fakeEventObject: any = { target: { value: "" } };
      onChange(fakeEventObject);
      this.inputRef.current?.focus();
    }
  };

  public get isEmpty() {
    return !this.props.value;
  }

  public renderClearIcon = () => {
    if (!this.isEmpty) {
      return <IconSvgClear onClick={this.onClear} />;
    }
  };

  public renderSearchIcon = () => {
    return <IconSvgSearch />;
  };

  public render() {
    return (
      <Input
        {...this.props}
        inputRef={this.inputRef}
        inputContainerClassName={this.props.inputContainerClassName}
        startIcon={this.renderSearchIcon}
        endIcon={this.renderClearIcon}
      />
    );
  }
}

export default SearchInput;
