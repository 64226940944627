import { types, Instance } from "mobx-state-tree";
import createHandleErrorAction, {
  Error,
} from "src/stores/action-creators/handle-error";

const ErrorStore = types
  .model({
    error: types.maybeNull(Error),
    needToRetry: false,
  })
  .volatile(() => ({
    _retry: null as (() => void) | null,
  }))
  .actions((self) => ({
    _handleError: createHandleErrorAction(self, "error"),
  }))

  .actions((self) => ({
    onError(error: any, customMessage?: string, retry?: () => void) {
      self._handleError(error, customMessage);
      self.needToRetry = !!retry;
      self._retry = retry || null;
    },
    resetError() {
      self.error = null;
    },
  }))
  .actions((self) => ({
    retry() {
      const retry = self._retry;
      if (retry) {
        self.error = null;
        self._retry = null;
        retry();
      } else {
        window.location.reload();
      }
    },
  }));

export default ErrorStore;
export interface IErrorStore extends Instance<typeof ErrorStore> {}
