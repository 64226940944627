import { types, Instance } from "mobx-state-tree";
import City from "./City";
import EmployeeType from "./EmployeeType";

export const Employee = types
  .model({
    id: types.identifier,
    firstName: types.string,
    middleName: types.maybeNull(types.string),
    lastName: types.string,
    city: types.maybeNull(City),
    employeeType: types.maybeNull(EmployeeType),
    photoUrl: types.string,
  })
  .views((self) => ({
    get fullName() {
      let name = `${self.lastName} ${self.firstName}`;
      if (self.middleName) {
        name += ` ${self.middleName}`;
      }

      return name;
    },
  }));

export interface IEmployee extends Instance<typeof Employee> {}
export interface IEmployeeSnapshotIn extends Instance<typeof Employee> {}
