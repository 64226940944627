export enum TypographyType {
  H6,
  TITLE,
  SUBTITLE,
  SUBTITLE_SLIM,
  BUTTON,
  CAPTION,
  BODY,
  LABEL
}

export enum TypographyColor {
  PRIMARY,
  SECONDARY,
  WHITE,
  ERROR,
  WARNING
}

export const TYPOGRAPHY_DEFAULT_TAG = "span";
