import cn from "classnames";
import Typography, {
  TypographyColor,
  TypographyType,
} from "src/components/Typography";
import React, { PureComponent } from "react";
import styles from "./ListItem.module.css";
import { ListItemProps } from "./ListItem.type";

export default class ListItem<T> extends PureComponent<ListItemProps<T>> {
  private onClick = () => {
    this.props.onClick(this.props.itemId);
  };

  public renderIcon() {
    if (this.props.isSelected && this.props.endIcon) {
      const Icon = this.props.endIcon;
      return <Icon className={styles.item__icon} />;
    }
    return null;
  }

  public render() {
    const { isSelected, typographyType, children } = this.props;
    return (
      <div
        onClick={this.onClick}
        className={cn(this.props.className, styles.item, {
          [styles.item_selected]: isSelected,
        })}
      >
        <Typography
          typographyType={
            typographyType !== undefined ? typographyType : TypographyType.BODY
          }
          typographyColor={TypographyColor.PRIMARY}
          className={styles.item__label}
        >
          {children}
        </Typography>
        {this.renderIcon()}
      </div>
    );
  }
}
