import { AxiosInstance } from "axios";

export enum ErrorListeners {
  UnAuthorized
}

export interface IBackendInterface {
  axios: AxiosInstance;
  addErrorListener(type: ErrorListeners, callback: () => void): void;
  removeErrorListener(type: ErrorListeners, callback: () => void): void;
}
