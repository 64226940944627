import { observer } from "mobx-react";
import React from "react";
import { FlatListProps } from "./FlatList.types";

@observer
class FlatList<T> extends React.Component<FlatListProps<T>> {
  public render() {
    const { data, renderItem, keyExtractor, placeholderComponent } = this.props;
    if (data.length === 0 && placeholderComponent) {
      return placeholderComponent();
    }
    return data.map((item: T, index) => {
      return (
        <React.Fragment key={keyExtractor(item)}>
          {renderItem(item, index)}
        </React.Fragment>
      );
    });
  }
}

export default FlatList;
