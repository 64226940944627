import FadeAnimation, { FADE_TYPE } from "src/components/FadeAnimation";
import Modal from "src/components/Modal";
import { ModalProps } from "src/components/Modal";
import React from "react";

export default class ModalDialog extends React.PureComponent<ModalProps> {
  public render() {
    const { children, isOpen, ...rest } = this.props;
    return (
      <Modal isOpen={isOpen} {...rest}>
        <FadeAnimation
          in={isOpen}
          timeout={700}
          unmountOnExit={true}
          enter={true}
          appear={true}
          exit={true}
          animation={FADE_TYPE.TOP_TO_BOTTOM}
        >
          {children}
        </FadeAnimation>
      </Modal>
    );
  }
}
