import DialogsWrapper from "../../components/DialogsWrapper";
import React from "react";
import { DialogType } from "../../types";
import {
  CommonDialogPayload,
  ErrorPayload,
  InfoPayload
} from "./DialogController.types";

export default class DialogController {
  public dialogsWrapperRef = React.createRef<DialogsWrapper>();

  public showDialog = (payload: CommonDialogPayload) => {
    if (this.dialogsWrapperRef.current) {
      const message = payload.message || "";

      return this.dialogsWrapperRef.current.addDialog({
        type: DialogType.COMMON,
        title: "",
        message,
        buttons: payload.buttons || [
          { title: payload.defaultButtonTitle || "OK" }
        ],
        hasCloseButton: payload.hasCloseButton ?? true,
        closeOnEsc: payload.closeOnEsc ?? true,
        onClose: payload.onClose
      });
    }
  };

  public showInfoDialog = (payload: InfoPayload) => {
    if (this.dialogsWrapperRef.current) {
      return this.dialogsWrapperRef.current.addDialog({
        type: DialogType.INFO,
        title: payload.title || "",
        message: payload.message,
        messageAlign: payload.messageAlign,
        buttons: payload.buttons || [
          { title: payload.defaultButtonTitle || "OK" }
        ],
        hasCloseButton: payload.hasCloseButton,
        closeOnEsc: payload.closeOnEsc,
        onClose: payload.onClose
      });
    }
  };

  public showErrorDialog = (payload: ErrorPayload) => {
    if (this.dialogsWrapperRef.current) {
      const message = payload.message || "";

      return this.dialogsWrapperRef.current.addDialog({
        type: DialogType.ERROR,
        title: payload.title || "",
        message,
        buttons: payload.buttons || [
          { title: payload.defaultButtonTitle || "OK" }
        ],
        hasCloseButton: payload.hasCloseButton ?? true,
        closeOnEsc: payload.closeOnEsc ?? true,
        onClose: payload.onClose
      });
    }
  };

  public closeAllDialogs = () => {
    if (this.dialogsWrapperRef.current) {
      this.dialogsWrapperRef.current.closeAll();
    }
  };
}
