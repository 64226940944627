import React from "react";
import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar";

class PerfectScrollbarContainer extends React.Component<
  ScrollBarProps & {
    forwardedRef?: React.Ref<PerfectScrollbar>;
  }
> {
  public render() {
    const { options, forwardedRef, ...rest } = this.props;

    return (
      <PerfectScrollbar
        options={{ minScrollbarLength: 40, ...(options || {}) }}
        ref={forwardedRef}
        {...rest}
      />
    );
  }
}

export default React.forwardRef(
  (props: ScrollBarProps, ref: React.Ref<PerfectScrollbar>) => (
    <PerfectScrollbarContainer {...props} forwardedRef={ref} />
  )
);
