import React from "react";
import styles from "./EmployeesTable.module.css";
import EmployeesTableItem from "./EmployeesTableItem";
import { ProvisionMap } from "src/types";
import { IEmployeeStore } from "src/stores/EmployeeStore";
import { IEditEmployeeStore } from "src/stores/EmployeeStore/EditEmployeeStore";
import injectWithObserver from "src/utils/mobx/injectWithObserver";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IUIStore, TableViewTypes } from "src/stores/UIStore";
import cn from "classnames";
import { observable, action } from "mobx";
import { DELETE_CONFIRMATION_QUESTION } from "src/const/messages";
import { isEditMode } from "src/utils/permissions";

interface EmployeesTableProps {
  employeeStore?: IEmployeeStore;
  editEmployeeStore?: IEditEmployeeStore;
  uiStore?: IUIStore;
}

const mapStateToProps = ({ rootStore }: ProvisionMap) => ({
  employeeStore: rootStore.employeeStore,
  editEmployeeStore: rootStore.employeeStore.editEmployeeStore,
  uiStore: rootStore.uiStore,
});
@injectWithObserver(mapStateToProps)
class EmployeesTable extends React.Component<EmployeesTableProps> {
  @observable
  private scrollBarContainer: HTMLElement | null = null;

  @action
  getScrollBarContainer = (container: HTMLElement) => {
    this.scrollBarContainer = container;
  };

  private onDeleteEmployee = (employeeId: string) => {
    const { deleteEmployee } = this.props.editEmployeeStore!;
    if (window.confirm(DELETE_CONFIRMATION_QUESTION)) {
      deleteEmployee(employeeId);
    }
  };

  public componentDidUpdate = () => {
    this.scrollBarContainer?.scrollTo({ top: 0 });
  };

  public render() {
    const { employees } = this.props.employeeStore!;
    const { open } = this.props.editEmployeeStore!;
    const { tableViewType } = this.props.uiStore!;
    return (
      <PerfectScrollbar
        className={styles.employeesTable__container}
        options={{
          minScrollbarLength: 100,
        }}
        containerRef={this.getScrollBarContainer}
      >
        <div
          className={cn(
            styles.employeesTable,
            tableViewType === TableViewTypes.GRID &&
              styles.employeesTable_gridView
          )}
        >
          {employees.map((employee) => (
            <EmployeesTableItem
              key={employee.id}
              employee={employee}
              showOperationsControls={isEditMode()}
              tableViewType={tableViewType}
              onEditClick={() => open(employee.id)}
              onDeleteClick={() => this.onDeleteEmployee(employee.id)}
            />
          ))}
        </div>
      </PerfectScrollbar>
    );
  }
}

export default EmployeesTable;
