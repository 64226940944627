import React from "react";
import DropDownFilter from "../base/DropDownFilter";
import styles from "./EmployeeTypesFilter.module.css";
import { IEmployeeTypeSnapshotIn } from "src/models/EmployeeType";

interface EmployeeTypesFilterProps {
  disabled?: boolean;
  employeeTypes: IEmployeeTypeSnapshotIn[];
  handleEmployeeTypeChange: (employeeType: IEmployeeTypeSnapshotIn) => void;
}

const DefaultEmployeeTypesFilterValue = {
  id: "",
  name: "Все отделы",
};
class EmployeeTypesFilter extends React.Component<EmployeeTypesFilterProps> {
  public render() {
    const { employeeTypes, handleEmployeeTypeChange, disabled } = this.props;

    return (
      <DropDownFilter
        items={[DefaultEmployeeTypesFilterValue, ...employeeTypes]}
        onChange={handleEmployeeTypeChange}
        defaultItem={DefaultEmployeeTypesFilterValue}
        buttonClassName={styles.employeeTypesFilter__button}
        menuClassName={styles.employeeTypesFilter__menu}
        disabled={disabled}
      />
    );
  }
}

export default EmployeeTypesFilter;
