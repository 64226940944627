import CreateEditEmployeeStore from "../base/CreateEditEmployeeStore";
import { Instance, flow, getRoot } from "mobx-state-tree";
import { addEmployee } from "src/api";
import { IRootStore } from "src/stores/RootStore";

const CreateEmployeeStore = CreateEditEmployeeStore.named("CreateEmployeeStore")
  .views((self) => ({
    get rootStore(): IRootStore {
      return getRoot<IRootStore>(self);
    },
  }))
  .actions((self) => ({
    addEmployee: flow(function* (formData: FormData) {
      self.fetchState.setIsLoading(true);
      try {
        const { status } = yield addEmployee(formData);
        if (status >= 400) {
          throw new Error();
        }
        self.close();
        self.employeeStore.loadEmployees();
      } catch (e) {
        self.rootStore.uiStore.errorStore.onError(
          e,
          "Error occurred while updating employee",
          () => {
            (self as ICreateEmployeeStore).addEmployee(formData);
          }
        );
      } finally {
        self.fetchState.setIsLoading(false);
      }
    }),
  }));

export default CreateEmployeeStore;
export interface ICreateEmployeeStore
  extends Instance<typeof CreateEmployeeStore> {}
