import React from "react";
// import cn from 'classnames'
import styles from "./Header.module.css";
import { ReactComponent as Logo } from "src/assets/logo.svg";

class Header extends React.Component {

  private onClickLogut = () => {

  }

  public render() {
    return (
      <div className={styles.header__container}>
        <header className={styles.header}>
          <div className={styles.header__logo}>
            <Logo />
          </div>
          <div className={styles.header__title}>
            <span>PHOTOS</span>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;