import React from "react";
import styles from "./EmployeesTableItem.module.css";
import { IEmployee } from "src/models/Employee";
import Button, { ButtonType, ButtonColor } from "src/components/Button";
import { ReactComponent as DeleteIcon } from "src/assets/icons/ic-24-delete.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/ic-24-edit.svg";
import PhotoPlaceholder from "src/assets/icons/pic-placeholder-attack.svg";
import cn from "classnames";
import { TableViewTypes } from "src/stores/UIStore";
import { observer } from "mobx-react";
import {BASE_URL} from "src/config/Constants"

interface EmployeesTableItemProps {
  employee: IEmployee;
  showOperationsControls: boolean;
  tableViewType: TableViewTypes;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

@observer
class EmployeesTableItem extends React.Component<EmployeesTableItemProps> {
  public render() {
    const { photoUrl, fullName, employeeType, city } = this.props.employee;
    const isGridView = this.props.tableViewType === TableViewTypes.GRID;
    return (
      <div
        className={cn(
          styles.employeesTableItem__container,
          isGridView && styles.employeesTableItem__container_gridView
        )}
      >
        <div
          className={cn(
            styles.employeesTableItem__img,
            isGridView && styles.employeesTableItem__img_gridView
          )}
          style={{
            backgroundImage: `url(${BASE_URL}/${photoUrl}), url(${PhotoPlaceholder})`,
          }}
        >
          {isGridView && this.props.showOperationsControls && (
            <>
              <div
                className={cn(
                  styles.employeesTableItem__img__control__container,
                  styles.employeesTableItem__img__editControl__container
                )}
                onClick={this.props.onEditClick}
              >
                <EditIcon className={styles.employeesTableItem__img__control} />
              </div>
              <div
                className={cn(
                  styles.employeesTableItem__img__control__container,
                  styles.employeesTableItem__img__deleteControl__container
                )}
                onClick={this.props.onDeleteClick}
              >
                <DeleteIcon
                  className={styles.employeesTableItem__img__control}
                />
              </div>
            </>
          )}
        </div>
        <div
          className={cn(
            styles.employeesTableItem__items,
            isGridView && styles.employeesTableItem__items_gridView
          )}
        >
          <div
            className={cn(
              styles.employeesTableItem__item,
              styles.employeesTableItem__item_name,
              isGridView && styles.employeesTableItem__item_gridView,
              isGridView && styles.employeesTableItem__item_name_gridView
            )}
          >
            {fullName}
          </div>
          <div
            className={cn(
              styles.employeesTableItem__item,
              styles.employeesTableItem__item_type,
              isGridView && styles.employeesTableItem__item_gridView,
              isGridView && styles.employeesTableItem__item_type_gridView
            )}
          >
            {employeeType && employeeType.name}
          </div>
          <div
            className={cn(
              styles.employeesTableItem__item,
              styles.employeesTableItem__city,
              isGridView && styles.employeesTableItem__item_gridView,
              isGridView && styles.employeesTableItem__city_gridView
            )}
          >
            {city && city.name}
          </div>
          {!isGridView && this.props.showOperationsControls && (
            <div
              className={cn(
                styles.employeesTableItem__item,
                styles.employeesTableItem__buttons,
                isGridView && styles.employeesTableItem__item_gridView
              )}
            >
              <Button
                key="edit"
                className={cn(
                  styles.employeesTableItem__button,
                  styles.employeesTableItem__editButton
                )}
                buttonType={ButtonType.HALF_COLORED}
                buttonColor={ButtonColor.SECONDARY}
                onClick={this.props.onEditClick}
                startIcon={EditIcon}
              />
              <Button
                key="delete"
                className={styles.employeesTableItem__button}
                buttonType={ButtonType.GRAYED}
                buttonColor={ButtonColor.SECONDARY}
                onClick={this.props.onDeleteClick}
                startIcon={DeleteIcon}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EmployeesTableItem;
