// import { getServerErrorMessage } from "utils/server-errors";
// import { ServerErrorCodes } from "utils/server-errors/codes";
import NetworkError from "./NetworkError";

export default class OfflineError extends NetworkError {
  constructor() {
    super({
      status: -1
    });

    this.code = this.status = -1;

    if (!this.message) {
      this.message = "NO INTERNET CONNECTION";
    }
  }
}
