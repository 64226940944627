import { CLIENT_ID } from "../../const/google";

const SCOPE_EMAIL = "email";

const ERROR_CLOSED_BY_USER = "popup_closed_by_user";
const ERROR_POPUP_BLOCKED_BY_BROWSER = "popup_blocked_by_browser";

export default class FirebaseService {
  private auth2: gapi.auth2.GoogleAuth | null = null;

  public init = async () => {
     if (this.auth2) {
      return;
    }

    await new Promise(resolve => {
      window.gapi.load('auth2', () => {
        this.auth2 = window.gapi.auth2.init({
          client_id: CLIENT_ID,
          ux_mode: "popup"
        })
        resolve()
      })
    })
  };

  public signInWithGoogle = async (): Promise<string|null|undefined> => {
    try {
      const result = await this.auth2?.grantOfflineAccess({
        scope: SCOPE_EMAIL
      });
      if (result?.code) {
        return result?.code;
      }
    } catch (e) {
      if (e?.error === ERROR_CLOSED_BY_USER) {
        return null;
      } else if (e?.error === ERROR_POPUP_BLOCKED_BY_BROWSER) {
        return null;
      } else {
        throw e;
      }
    }
  };
}
