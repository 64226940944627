import React from "react";
import { IEmployeeSnapshotIn } from "src/models/Employee";
import { ICitySnapshotIn } from "src/models/City";
import { IEmployeeTypeSnapshotIn } from "src//models/EmployeeType";
import ModalDialog from "src/components/ModalDialog";
import styles from "./CreateEditEmployee.module.css";
import Button, { ButtonColor, ButtonType } from "src/components/Button";
import { ReactComponent as CloseIcon } from "src/assets/icons/ic-24-cross.svg";
import { ReactComponent as DeleteIcon } from "src/assets/icons/ic-24-delete.svg";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { EmployeeFormData, EmployeeFormFields } from "./Form/Form";

interface CreateEditEmployeeProps {
  employee?: IEmployeeSnapshotIn;
  onSubmit: (formData: FormData) => void;
  onDelete?: (employeeId: string) => void;
  onClose: () => void;
  cities: ICitySnapshotIn[];
  employeeTypes: IEmployeeTypeSnapshotIn[];
}

const CreateEditEmployee = ({
  employee,
  onSubmit: onSubmitBase,
  onDelete,
  onClose: onCloseBase,
  cities,
  employeeTypes,
}: CreateEditEmployeeProps) => {
  const getTitle = () => {
    return employee ? "Edit Employee" : "New Employee";
  };

  const getDefaultCityFieldValue = () => {
    return employee && employee.city ? employee.city : { id: "-1", name: "" };
  };

  const getDefaultEmployeeTypeFieldValue = () => {
    return employee && employee.employeeType
      ? employee.employeeType
      : { id: "-1", name: "" };
  };

  const form = useForm<EmployeeFormData>({
    defaultValues: {
      [EmployeeFormFields.FIRST_NAME]: employee ? employee.firstName : "",
      [EmployeeFormFields.MIDDLE_NAME]: (employee && employee.middleName) || "",
      [EmployeeFormFields.LAST_NAME]: employee ? employee.lastName : "",
      [EmployeeFormFields.CITY]: (employee && employee.city) || undefined,
      [EmployeeFormFields.EMPLOYEE_TYPE]:
        (employee && employee.employeeType) || undefined,
    },
    mode: "onChange",
  });


  const onClose = () => {
    onCloseBase();
    form.reset();
  };

  const onSubmit = async (data: EmployeeFormData) => {
    let formData = new FormData();

    const photo = data.photo && data.photo[0];

    formData.append("FirstName", data.firstName.trim());
    formData.append("MiddleName", data.middleName.trim());
    formData.append("LastName", data.lastName.trim());
    photo && formData.append("Photo", photo);
    formData.append("CityId", data.city.id);
    formData.append("EmployeeTypeId", data.employeeType.id);

    onSubmitBase(formData);
  };

  return (
    <ModalDialog isOpen={true}>
      <div className={styles.createEditEmployee__container}>
        <div className={styles.createEditEmployee}>
          <div className={styles.createEditEmployee__header}>
            <div className={styles.createEditEmployee_title}>{getTitle()}</div>
            <Button
              className={styles.createEditEmployee__close_button}
              startIcon={CloseIcon}
              buttonColor={ButtonColor.SECONDARY}
              buttonType={ButtonType.FLAT}
              onClick={onClose}
            />
          </div>
          <Form
            form={form}
            employee={employee}
            cities={cities}
            getDefaultCityFieldValue={getDefaultCityFieldValue}
            employeeTypes={employeeTypes}
            getDefaultEmployeeTypeFieldValue={getDefaultEmployeeTypeFieldValue}
          />
          <div className={styles.createEditEmployee__footer}>
            {employee ? (
              <Button
                key="delete"
                className={styles.unit__controls_remove}
                buttonType={ButtonType.GRAYED}
                buttonColor={ButtonColor.SECONDARY}
                onClick={() => onDelete && onDelete(employee.id)}
                startIcon={DeleteIcon}
              />
            ) : (
              <Button
                className={styles.createEditEmployee__footer_button}
                buttonColor={ButtonColor.PRIMARY}
                buttonType={ButtonType.OUTLINED}
                onClick={onClose}
              >
                Cancel
              </Button>
            )}

            <Button
              type="submit"
              className={styles.createEditEmployee__footer_button}
              buttonColor={ButtonColor.PRIMARY}
              buttonType={ButtonType.FILLED}
              onClick={form.handleSubmit(onSubmit)}
              disabled={!form.formState.isValid}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default CreateEditEmployee;
